// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * Configuration
 */

:root {
  --min-chat-height: 200px;
  --max-chat-height: 700px;

  /* Legacy Launcher Sizes */
  --height-launcher-size-small: 50px;
  --height-launcher-size-medium: 60px;
  --height-launcher-size-large: 80px;

  /* Launcher Mobile Community Rounded */
  --height-launcher-rounded-size-community-mobile: 40px;
  --width-launcher-rounded-size-community-mobile: 300px;

  /* Grabber Mobile */
  --height-grabber-size-mobile: 50px;

  /* Chat Visible Launcher Community */
  --height-chat-visible-size-community: 60px;
  --width-chat-visible-size-community: 60px;

  /* Launcher Community Rounded */
  --height-launcher-rounded-size-community: 60px;
  --width-launcher-rounded-size-community: 300px;

  /* Auto messages */
  --width-launcher-size-community-mobile-grabber: 85px;
  --width-launcher-size-community-grabber: 160px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
