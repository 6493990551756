import CONSTANTS from '../helpers/constants';
import Settings from '../helpers/settings';
import Iframe from '../helpers/iframe';
import Vanilla from '../helpers/vanilla';

const defaultSettings = {
  events: [],
};

const processEvent = function (externalEvent) {
  if (externalEvent) {
    const { events } = Settings.getAll();
    if (events) {
      let eventObject = externalEvent;
      if (typeof (externalEvent) === 'string') {
        try {
          eventObject = JSON.parse(externalEvent);
          // eslint-disable-next-line no-empty
        } catch (ex) {}
      }
      const eventId = eventObject.id;
      const eventData = eventObject.data || {};

      if (eventId && eventData) {
        Vanilla.debugMessage(`Processing event ${eventId}`, eventData);
        // Find event handlers
        for (let i = 0; i < events.length; i += 1) {
          const event = events[i];
          const eId = event.id;
          const eventHandler = event.handler;
          const namespacedEvent = CONSTANTS.GUURU_EVENT_NAMESPACE + eId;
          if (namespacedEvent === eventId && undefined !== eventHandler) {
            eventHandler(eventId, eventData);
          }
        }
      }
    }
  }
};

const handleGuuruChatEvent = function (iframeEvent) {
  processEvent(iframeEvent.data);
};

const prepareEventsSettings = function () {
  const { events } = Settings.getAll();
  if (events) {
    Iframe.bindIframeEvent(window, 'message', handleGuuruChatEvent);
  }
};

const sendEventToIframe = (iframeNode, payload) => {
  if (!iframeNode) return;
  iframeNode.contentWindow.postMessage(
    payload,
    process.env.CHAT_ENDPOINT,
  );
};

const HELPER = {
  defaultSettings,
  prepareEventsSettings,
  processEvent,
  sendEventToIframe,
};

export default HELPER;
