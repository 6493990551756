// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-heroes-t_Cfy {
  display: flex;
  flex-direction: row;
  position: relative;
}

.index-hero-JQE4r {
  border-radius: 50%;
  border: 2px solid white;
  background-size: cover;
  background-position: center center;
  box-shadow: rgba(0 0 0 / 35%) 2px 2px 3px -1px;
  box-sizing: border-box;
  display: block !important;
}

.index-hero-JQE4r:not(:first-child) {
  margin-left: -15px;
}

.index-green-dot-eyVtF {
  background-color: #52D191;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #fff;
  position: absolute;
}

.index-green-dot-big-ayP4C {
  bottom: 3px;
  right: 5px;
}

.index-green-dot-small-aXKIh {
  bottom: 3px;
  right: 0;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heroes": `index-heroes-t_Cfy`,
	"hero": `index-hero-JQE4r`,
	"green-dot": `index-green-dot-eyVtF`,
	"greenDot": `index-green-dot-eyVtF`,
	"green-dot-big": `index-green-dot-big-ayP4C`,
	"greenDotBig": `index-green-dot-big-ayP4C`,
	"green-dot-small": `index-green-dot-small-aXKIh`,
	"greenDotSmall": `index-green-dot-small-aXKIh`
};
export default ___CSS_LOADER_EXPORT___;
