const HELPER = {
  async getCommunityStats(appId) {
    let stats = JSON.parse(sessionStorage.getItem('community-stats'));
    if (!stats) {
      const response = await fetch(`https://${process.env.BUCKET_STATIC_DOMAIN}/partners/${appId}/loader/stats.json`);
      if (!response.ok) {
        return null;
      }
      stats = await response.json();
      sessionStorage.setItem('community-stats', JSON.stringify(stats));
    }
    return stats;
  },
};

export default HELPER;
