import Vanilla from '../helpers/vanilla';
import Iframe from '../helpers/iframe';
import Settings from '../helpers/settings';

const defaultSettings = {
  smartForm: {
    enabled: false,
    selector: undefined,
    width: '100%',
    height: '1200px',
    contactPage: undefined,
    wrapperAction: 'appendChild',
    alignment: 'start',
  },
};

const HEIGHT_UPDATED_EVENT_ID = 'chat:heightUpdated';
const EXPAND_EVENT_ID = 'chat:formExpand';
const SHRINK_EVENT_ID = 'chat:formShrink';
const PROMPT_CLIENT_FORM = 'chat:delegateToClientForm';

const handleResizeEvent = (action) => {
  const iframeWrapper = document.getElementsByClassName('guuru-smart-form-wrapper')[0];
  const iframe = document.getElementById('GuuruContact');
  const smartFormSelector = document.getElementsByClassName('guuru-smart-form')[0];
  iframeWrapper.classList[action]('guuru-smart-form-expand-iframe-wrapper');
  iframe.classList[action]('guuru-smart-form-expand-iframe');
  smartFormSelector.classList[action]('guuru-smart-form-expanded');
};

const handleUpdatedHeight = (height) => {
  const iframe = document.getElementById('GuuruContact');
  iframe.style.height = `${height}px`;
};

const handleShowClientForm = (question) => {
  const { questionSelector } = Settings.get('smartForm');
  const iframeWrapper = document.getElementsByClassName('guuru-smart-form-wrapper')[0];
  Vanilla.revertReplaceChild(iframeWrapper.parentElement);
  if (questionSelector && question) {
    const elementToInjectQuestion = (
      document.getElementById(questionSelector)
      || document.querySelector(questionSelector)
    );
    if (elementToInjectQuestion) {
      elementToInjectQuestion.focus();
      elementToInjectQuestion.value = question;
      window.scroll(0, 0);
    }
  }
};

const handleMessage = (event) => {
  let { data } = event;
  if (!data) data = event.originalEvent?.data;
  if (event.origin !== process.env.FORM_ENDPOINT) return null;

  switch (data?.id) {
    case EXPAND_EVENT_ID:
      handleResizeEvent('add');
      break;
    case SHRINK_EVENT_ID:
      handleResizeEvent('remove');
      break;
    case HEIGHT_UPDATED_EVENT_ID:
      handleUpdatedHeight(data.data.height);
      break;
    case PROMPT_CLIENT_FORM:
      handleShowClientForm(data?.data?.question);
      break;
    default:
      break;
  }

  return null;
};

const HELPER = {
  defaultSettings,

  isEnabled() {
    const { enabled = false } = Settings.get('smartForm');
    return enabled;
  },

  attachForm() {
    const settings = Settings.getAll();
    const {
      smartForm: {
        contactPage,
        wrapperAction,
        enabled,
        selector,
        alignment,
      },
    } = settings;

    if (
      !enabled
      || !selector
      || !contactPage
    ) return;

    const currentPageUrl = window.location.href;
    const currentPageUrlMatches = contactPage.find((urlPattern) => {
      const rePattern = new RegExp(urlPattern, 'g');
      return !!(currentPageUrl.match(rePattern));
    });
    if (!currentPageUrlMatches) {
      Vanilla.debugMessage(`Form not attached (reason: settings.smartForm.contactPage=${contactPage})`);
      return;
    }

    const wrapper = document.querySelector(selector);

    if (!wrapper) return;

    wrapper.classList.add('guuru-smart-form');

    const iframe = wrapper.querySelector('iframe#GuuruContact');
    if (iframe) iframe.remove();

    const element = document.createElement('iframe');
    const src = Iframe.getIframeUrl({
      ...settings,
      channel: 'form',
      refererTrigger: undefined,
    });
    element.src = src;
    element.id = 'GuuruContact';
    element.className = 'guuru-contact';
    element.style = `
    enabled: ${enabled};
    border-radius: 2px;
    width: ${defaultSettings.smartForm.width};
    border: 0;
    min-height: 270px;
    max-height: ${defaultSettings.smartForm.height};
    transition: height 0.2s linear;
  `;

    const iframeWrapper = document.createElement('div');
    iframeWrapper.className = 'guuru-smart-form-wrapper';
    iframeWrapper.style = `
    text-align: ${alignment};
    display: flex;
    `;

    iframeWrapper.appendChild(element);

    Iframe.bindIframeEvent(window, 'message', handleMessage);

    Vanilla[wrapperAction](wrapper, iframeWrapper);
  },

  isAttached() {
    const settings = Settings.getAll();
    const { smartForm: { selector } } = settings;
    const wrapper = document.querySelector(selector);
    if (!wrapper) {
      return false;
    }
    const iframe = wrapper.querySelector('iframe#GuuruContact');
    return !!iframe;
  },
};

export default HELPER;
