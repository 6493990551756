import Vanilla from '../helpers/vanilla';
import Settings from '../helpers/settings';
import Launcher from './launcher';
import Storage from '../helpers/storage';
import CONSTANTS from '../helpers/constants';
import ChatWidget from './chatWidget';

const defaultSettings = {
  disableOn: {
    days: [],
  },
  launcher: {
    displayRules: {
      hideOnLoad: false,
      locales: undefined,
      includeUrls: undefined,
      excludeUrls: undefined,
      includeCategories: undefined,
      excludeCategories: undefined,
      targetDevices: undefined,
    },
  },
};

const showLoaderInIncludedCategory = (enabled) => {
  const {
    launcher: { displayRules: { includeCategories } },
    category,
  } = Settings.getAll();
  let isEnabled = enabled;
  if (Array.isArray(includeCategories) && includeCategories.length > 0) {
    isEnabled = includeCategories.indexOf(category) > -1;
    if (!isEnabled) {
      Vanilla.debugMessage('Chat is not enabled (reason: settings.launcher.displayRules.includeCategories).');
    }
  }
  return isEnabled;
};

const showLoaderInExcludedCategory = (enabled) => {
  const {
    launcher: { displayRules: { excludeCategories } },
    category,
  } = Settings.getAll();
  let isEnabled = enabled;
  if (Array.isArray(excludeCategories) && excludeCategories.length > 0) {
    if (excludeCategories.indexOf(category) > -1) {
      isEnabled = false;
      Vanilla.debugMessage('Chat is not enabled (reason: settings.launcher.displayRules.excludeCategories).');
    }
  }
  return isEnabled;
};

const isEnabledByCategory = function () {
  let enabled = true;
  enabled = showLoaderInIncludedCategory(enabled);
  enabled = showLoaderInExcludedCategory(enabled);
  return enabled;
};

const isHideOnLoad = function () {
  const { launcher: { displayRules: { hideOnLoad } } } = Settings.getAll();
  return !!hideOnLoad;
};

const initializeEnabledByCategory = function () {
  const {
    launcher: { displayRules: { includeCategories, excludeCategories } },
  } = Settings.getAll();
  if (includeCategories || excludeCategories) {
    if (!isEnabledByCategory()) {
      Launcher.hideChatLoader();
    }
  }
};

const initializeEnabledByUrl = function () {
  if (!Launcher.isEnabledByUrl()) {
    Launcher.hideChatLoader();
  }
};

const initDisplayRules = function () {
  const {
    autoMessages,
    launcher: { displayRules: { hideOnLoad, targetDevices } },
  } = Settings.getAll();
  if (targetDevices
    && !Vanilla.isForCurrentDeviceTarget(targetDevices)) {
    Launcher.hideChatLoader();
    Vanilla.debugMessage(`Chat is not enabled (reason: settings.displayRulesTargetDevices=${targetDevices}).`);
  }

  const chatPreviouslyOpen = Storage.getItem(CONSTANTS.CHAT_LOADER_OPEN);
  const hasUpdatedAutoMessages = autoMessages.find((autoMessage) => (
    Date.parse(autoMessage.updatedAt) > chatPreviouslyOpen
  ));

  const isDesktop = window.screen.availWidth
    >= CONSTANTS.DEVICE_DESKTOP_MIN_RES;
  if (
    chatPreviouslyOpen
    && !hasUpdatedAutoMessages
    && isDesktop
    && Launcher.showLoaderInExcludedPageUrl(true)
    && showLoaderInExcludedCategory(true)
  ) {
    ChatWidget.showChat();
    Vanilla.addClass(document.body, 'guuru-chat-visible-auto');
    return;
  }

  if (hideOnLoad) {
    Launcher.hideChatLoader();
    Vanilla.debugMessage('Chat is not enabled (reason: settings.displayRulesdisplayRulesHideOnLoad).');
  } else {
    initializeEnabledByUrl();
    initializeEnabledByCategory();
  }
};

const isEnabledByLocale = function () {
  const {
    launcher: { displayRules: { locales } },
    locale,
  } = Settings.getAll();

  // Disable for some locales
  if (Array.isArray(locales)) {
    // Check if current locale belongs to rules list
    const rulesLocales = locales;
    if (!Vanilla.contains(rulesLocales, locale)) {
      const debugMsg = 'Chat is not enabled'
          + ' (reason: settings.displayRulesLocales). Details: '
          + `settings.displayRulesLocales: ${rulesLocales}, `
          + `locale: ${locale}`;
      Vanilla.debugMessage(debugMsg);
      return false;
    }
  }

  return true;
};

const isEnabledByDays = function () {
  const { disableOn } = Settings.getAll();
  if (disableOn.days.length > 0) {
    const todayDate = new Date();
    // need to add one to get current month as it is start with 0
    const todayDay = todayDate.getDate();
    const todayMonth = todayDate.getMonth() + 1;
    const todayYear = todayDate.getFullYear();
    const todayString = `${todayDay}/${todayMonth}/${todayYear}`;

    for (let i = 0; i < disableOn.days.length; i += 1) {
      const dateString = disableOn.days[i];
      if (dateString === todayString) {
        Vanilla.debugMessage('Chat is not enabled (reason: settings.disableOnDays)');
        return false;
      }
    }
  }

  return true;
};

const HELPER = {
  defaultSettings,
  initDisplayRules,
  isEnabledByCategory,
  isEnabledByLocale,
  isEnabledByDays,
  isHideOnLoad,
};

export default HELPER;
