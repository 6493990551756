/* eslint-env browser */
class Gtag {
  #initialized = false;

  #userProperties = {
    account: 'account',
  };

  #eventProperties = {
    partner: 'partner',
    locale: 'locale',
    chatCategory: 'chat_category',
    chatChannel: 'chat_channel',
    chatSource: 'chat_source',
  };

  #properties = {};

  // eslint-disable-next-line class-methods-use-this
  #loadGa = (measurementId) => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    script.id = 'guuru-analytics';
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
  };

  // eslint-disable-next-line class-methods-use-this
  #gtag = (...args) => {
    if (!window) return;
    if (!window.gtag) {
      window.gtag = function () {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments);
      };
    }

    window.gtag(...args);
  };

  #isGaEnabled = () => this.#initialized && (
    process.env.CHAT_GA_MEASUREMENT_ID
      || process.env.EXPERT_WEB_GA_MEASUREMENT_ID
      || process.env.PAGES_GA_MEASUREMENT_ID
  ) && window.gtag;

  // eslint-disable-next-line class-methods-use-this
  #viewportDimensions = () => {
    const { clientWidth, clientHeight } = document.documentElement;
    const width = Math.max(clientWidth, window.innerWidth || 0);
    const height = Math.max(clientHeight, window.innerHeight || 0);
    return `${width}x${height}`;
  };

  initialize = (measurementId, options) => {
    if (this.#initialized) return;

    this.#loadGa(measurementId);

    this.measurementId = measurementId;
    this.#gtag('js', new Date());
    this.#gtag('config', this.measurementId, {
      send_page_view: false,
      ...options,
    });
    this.#initialized = true;
  };

  event = (eventName, args) => {
    if (!this.#isGaEnabled()) return;

    this.#gtag('event', eventName, {
      ...args,
      viewport_size: this.#viewportDimensions(),
      send_to: this.measurementId,
    });
  };

  set = (...args) => {
    if (!this.#isGaEnabled()) return;

    this.#gtag('set', ...args);
  };

  setProperties = (args) => {
    this.#properties.user_properties = Object.entries(args)
      .filter(([key]) => this.#userProperties[key])
      .reduce((acc, [key, value]) => (
        Object.assign(acc, { [this.#userProperties[key]]: value })
      ), this.#properties?.user_properties ?? {});

    this.#properties = Object.entries(args)
      .filter(([key]) => this.#eventProperties[key])
      .reduce((acc, [key, value]) => (
        Object.assign(acc, { [this.#eventProperties[key]]: value })
      ), this.#properties);
  };

  track = (
    label,
    category,
    action,
    { nonInteraction = false, value, args = {} } = {},
  ) => {
    this.event(action, {
      event_category: category,
      event_label: label,
      event_action: action,
      non_interaction: nonInteraction,
      ...this.#properties,
      ...(value !== undefined ? { value } : {}),
      ...args,
    });
  };
}

export default new Gtag();
