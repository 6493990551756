import getEmbedExtendedHtml from '../../../communityEmbedExtended';
import Constants from '../../../../../helpers/constants';
import styles from './index.css';
import Settings from '../../../../../helpers/settings';

export default async function ({
  avatars,
  contentText: contentEmbedCommunityTitle,
  contentEmbedCommunityDescription,
  contentEmbedCommunityPhotoUrl,
  contentEmbedColor,
  contentEmbedShowBanner,
}, imageHeight = '250px', showExtended = true) {
  if (!contentEmbedShowBanner) return '';

  const {
    enabled,
    locale,
    contentEmbed: {
      contentEmbedButtonLabel,
    } = {},
  } = Settings.getAll();

  const extendedEmbed = (enabled && showExtended)
    ? await getEmbedExtendedHtml({
      avatars,
      contentText: contentEmbedButtonLabel?.[locale ?? 'en'],
      buttonDescriptionText: null,
      embedButtonColor: contentEmbedColor,
      selector: Constants.SELECTOR.contentEmbed,
    })
    : '';

  return `
    <div>
      <div style="background-image: url(${contentEmbedCommunityPhotoUrl}); height: ${imageHeight}" class="${styles.guuruCommunityHero}"></div>
        <div class="${styles.guuruCommunityBlock}">
          <p>${contentEmbedCommunityTitle}</p>
          <p>${contentEmbedCommunityDescription}</p>
        </div>
        ${extendedEmbed}
    </div>
  `;
}
