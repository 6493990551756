// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes index-rotation-uIPRk {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.index-pagination-Y0QfO {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  user-select: none;

  button {
    cursor: pointer;
    padding: 8px 16px;
    background-color: transparent;
    border: none;
    color: black;
    font-weight: 500;
  }

  button:disabled {
    color: #b6b6b6;
    cursor: auto;
  }

  i {
    font-size: 12px;
  }

  span:nth-child(2) {
    font-weight: bold;
  }

  span:nth-child(3) {
    color: #d2d2d2;
  }

  @media only screen and (max-width: 1024px) {
    position: unset;
    transform: unset;
    padding: 10px;
  }
}

.index-overlay-SezGb {
  opacity: 0.3;
  pointer-events: none;
}

.index-loader-QupYk {
  top: 30%;
  left: 50%;
  position: absolute;
  width: 30px;
  height: 30px;
  border: 5px solid var(--guuru-embed-color);
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: index-rotation-uIPRk 1s linear infinite;
  z-index: 20;
}

.index-arrow-down-SsYGg {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  margin: 0 0 3px 4px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `index-pagination-Y0QfO`,
	"overlay": `index-overlay-SezGb`,
	"loader": `index-loader-QupYk`,
	"rotation": `index-rotation-uIPRk`,
	"arrow-down": `index-arrow-down-SsYGg`,
	"arrowDown": `index-arrow-down-SsYGg`
};
export default ___CSS_LOADER_EXPORT___;
