// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-embed-extended-info-Kh4rn {
  display: flex;
  margin: 10px 0 6px;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.index-embed-extended-stats-qCusD {
  margin-left: 1%;
}

.index-expert-count-vWHjl {
  margin: 0 !important;
  font-weight: 600;
  font-size: 16px;
}

.index-average-rating-eTbwY {
  margin: 0 !important;
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"embed-extended-info": `index-embed-extended-info-Kh4rn`,
	"embedExtendedInfo": `index-embed-extended-info-Kh4rn`,
	"embed-extended-stats": `index-embed-extended-stats-qCusD`,
	"embedExtendedStats": `index-embed-extended-stats-qCusD`,
	"expert-count": `index-expert-count-vWHjl`,
	"expertCount": `index-expert-count-vWHjl`,
	"average-rating": `index-average-rating-eTbwY`,
	"averageRating": `index-average-rating-eTbwY`
};
export default ___CSS_LOADER_EXPORT___;
