// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-anchor-asxfw {
  display: flex;
  width: 100%;

  a {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }
}


`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"anchor": `index-anchor-asxfw`
};
export default ___CSS_LOADER_EXPORT___;
