import CONSTANTS from './constants';

const HELPER = {
  getAll() {
    return JSON.parse(localStorage.getItem(CONSTANTS.GUURU_STATE) || '{}');
  },
  setAll(values) {
    if (Object.keys(values).length > 0) {
      localStorage.setItem(CONSTANTS.GUURU_STATE, JSON.stringify(values));
    }
  },
  getItem(key, namespace) {
    const values = HELPER.getAll();
    if (namespace in values) {
      return values[namespace] && values[namespace][key];
    }
    return values[key];
  },
  removeItem(key, namespace) {
    const values = HELPER.getAll();
    if (namespace in values) {
      delete values[namespace][key];
    } else {
      delete values[key];
    }
    HELPER.setAll(values);
  },
  setItem(key, value, namespace) {
    const values = HELPER.getAll();
    if (namespace) {
      Object.assign(
        values,
        { [namespace]: { ...values[namespace], ...{ [key]: value } } },
      );
    } else {
      values[key] = value;
    }
    HELPER.setAll(values);
  },
  isLocalStorageAvailable() {
    const test = 'feature_test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (err) {
      return false;
    }
  },
  isIndexDBAvailable() {
    return window.indexedDB !== undefined;
  },
};

export default HELPER;
