import Launcher from './launcher';
import SmartForm from './smartForm';

const originalPageTitle = document.title;

const handleAddNewMessageNotification = function ({ unreadMessagesCount }) {
  if (unreadMessagesCount > 0
    && (document.hidden || !window.guuru.isChatOpened())
  ) {
    const count = unreadMessagesCount <= 9 ? unreadMessagesCount : '9+';
    document.title = `(${count}) ${originalPageTitle}`;
  }
};

const handleResetNotificationCounter = function () {
  if (window.guuru.isChatOpened()
    || !Launcher.isEnabledByUrl()
    || SmartForm.isAttached()
  ) {
    document.title = originalPageTitle;
  }
};

const HELPER = {
  handleAddNewMessageNotification,
  handleResetNotificationCounter,
};
export default HELPER;
