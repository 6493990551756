// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-guuru-headline-A6y_R {
  padding-bottom: 40px;
}

.index-footer-rjBeC {
  margin: 12px 0;
  padding: 24px 0;
  display: flex;
  align-items: center;
  position: relative;

  @media only screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
}

.index-footer-rjBeC:not(:has(*)) {
  display: none;
}

.index-mentions-wrapper-hkfZA {
  display: flex;
  flex-flow: row nowrap;
  gap: 48px;

  > div:first-child {
    position: relative;
    flex: 2;
  }

  > div:nth-child(2) {
    flex: 1;

    &:empty {
      display: none;
    }
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 25px;

    > div:first-child {
      flex: unset;
    }

    > div:nth-child(2) {
      flex: unset;
    }
  }
}

.index-guuru-mention-container-qTquv {
  display: flex;
  gap: 24px;

  .index-mentions-container-tunKH {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .index-mentions-wk1n8 {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    min-height: 180px;

    @media only screen and (max-width: 1024px) {
      min-height: 260px;
    }
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"guuru-headline": `index-guuru-headline-A6y_R`,
	"guuruHeadline": `index-guuru-headline-A6y_R`,
	"footer": `index-footer-rjBeC`,
	"mentions-wrapper": `index-mentions-wrapper-hkfZA`,
	"mentionsWrapper": `index-mentions-wrapper-hkfZA`,
	"guuru-mention-container": `index-guuru-mention-container-qTquv`,
	"guuruMentionContainer": `index-guuru-mention-container-qTquv`,
	"mentions-container": `index-mentions-container-tunKH`,
	"mentionsContainer": `index-mentions-container-tunKH`,
	"mentions": `index-mentions-wk1n8`
};
export default ___CSS_LOADER_EXPORT___;
