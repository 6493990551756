// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable selector-class-pattern, selector-id-pattern */
/* stylelint-disable selector-descendant-combinator-no-non-space */
/* stylelint-disable indentation */
/* stylelint-disable selector-pseudo-class-parentheses-space-inside */

.index-launcher-community-EAGgc {
  display: flex;
  height: var(--height-launcher-rounded-size-community);
  padding: 10px;
  align-items: center;
  box-sizing: border-box !important;
}

@media (hover: hover) {
  .index-launcher-community-EAGgc.index-launcher-community-EAGgc:hover {
    justify-content: center;
  }

  .index-launcher-community-EAGgc.index-launcher-community-EAGgc:hover .index-title-ulU_o,
  .index-launcher-community-EAGgc.index-launcher-community-EAGgc:hover .heroes {
    display: none;
  }

  .index-launcher-community-EAGgc.index-launcher-community-EAGgc:hover .index-details-uh96F {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    padding: 5px;
    line-height: 1.2;
    width: 92%;
    min-width: 200px;
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .index-launcher-community-EAGgc {
    padding: 10px;
    height: inherit;
  }
}

.index-details-uh96F {
  display: none;
}

.index-title-ulU_o {
  display: flex;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0 10px;
  text-align: center;
}

.index-title-ulU_o strong {
  font-weight: bold !important;
  color: inherit !important;
}

@media only screen and (max-width: 768px) {
  .index-title-ulU_o {
    font-size: 13px;
    margin: 0 2px 0 10px;
    line-height: 1.1em;
  }
}


@media only screen and (max-width: 768px) {
  .index-launcher-community-EAGgc .hero-avatar {
    width: 26px !important;
    height: 26px !important;
  }

  .index-launcher-community-EAGgc :nth-child(2) {
    display: none !important;
  }

  .index-launcher-community-EAGgc .green-dot {
    bottom: 1px;
    right: -2px;
    width: 8px;
    height: 8px;
  }
}

.index-launcher-title-o19v8 {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .index-launcher-title-o19v8 {
    display: none;
  }
}

.index-launcher-title-small-H3tzf {
  display: none;
}

@media only screen and (max-width: 768px) {
  .index-launcher-title-small-H3tzf {
    display: block;
  }
}

.guuru-launcher.mod--format-community.mod--type-round,
.guuru-launcher.mod--format-community.mod--type-squared {
  width: auto;
  height: var(--height-launcher-rounded-size-community);
  max-width: var(--width-launcher-rounded-size-community);
}

.guuru-launcher.mod--format-community.mod--type-round
.index-hide-title-J85g5,
.guuru-launcher.mod--format-community.mod--type-squared
.index-hide-title-J85g5 {
  display: none;
}

.guuru-launcher.mod--format-community.mod--type-round.mod--position-bottomLeft
.guuru-launcher-button,
.guuru-launcher.mod--format-community.mod--type-squared.mod--position-bottomLeft
.guuru-launcher-button {
  border-radius: 50px 50px 50px 0;
}

.guuru-launcher.mod--format-community.mod--type-round.mod--position-bottomRight
.guuru-launcher-button,
.guuru-launcher.mod--format-community.mod--type-squared.mod--position-bottomRight
.guuru-launcher-button {
  border-radius: 50px 50px 0;
}

@media only screen and (max-width: 768px) {
  .guuru-launcher.mod--format-community.mod--type-round,
  .guuru-launcher.mod--format-community.mod--type-squared {
    width: auto;
    height: var(--height-launcher-rounded-size-community-mobile);
    max-width: var(--width-launcher-rounded-size-community-mobile);
  }
}

.guuru-launcher.mod--format-community.mod--type-squared
.guuru-launcher-button {
  border-radius: 0 !important;
}

.guuru-chat-visible #GuuruMain .index-launcher-community-EAGgc {
  display: none;
}

.guuru-chat-visible .guuru-launcher.mod--format-community {
  height: var(--height-chat-visible-size-community) !important;
  width: var(--width-chat-visible-size-community) !important;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"launcher-community": `index-launcher-community-EAGgc`,
	"launcherCommunity": `index-launcher-community-EAGgc`,
	"title": `index-title-ulU_o`,
	"details": `index-details-uh96F`,
	"launcher-title": `index-launcher-title-o19v8`,
	"launcherTitle": `index-launcher-title-o19v8`,
	"launcher-title-small": `index-launcher-title-small-H3tzf`,
	"launcherTitleSmall": `index-launcher-title-small-H3tzf`,
	"hide-title": `index-hide-title-J85g5`,
	"hideTitle": `index-hide-title-J85g5`
};
export default ___CSS_LOADER_EXPORT___;
