// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable selector-id-pattern, selector-class-pattern */

.attention-attention-grabber-content-XowTW {
  box-shadow: 0 1px 6px rgba(0 0 0 / 16%), 0 2px 32px rgba(0 0 0 / 16%);
  padding: 15px;
  border-radius: 10px;
  color: white;
}

.mod--format-community .attention-attention-grabber-content-XowTW {
  background-color: white;
}

.mod--format-community .attention-attention-grabber-content-XowTW span {
  color: black;
}

.mod--type-squared .mod--format-community .attention-attention-grabber-content-XowTW {
  border-radius: 0;
}

.attention-attention-grabber-message-_6fEZ {
  font-size: 13px;
  font-weight: 300;
  overflow-wrap: break-word;
}

@media only screen and (width <= 768px) {
  .mod--format-community .attention-attention-grabber-message-_6fEZ {
    text-align: center;
    line-height: 1.3;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attention-grabber-content": `attention-attention-grabber-content-XowTW`,
	"attentionGrabberContent": `attention-attention-grabber-content-XowTW`,
	"attention-grabber-message": `attention-attention-grabber-message-_6fEZ`,
	"attentionGrabberMessage": `attention-attention-grabber-message-_6fEZ`
};
export default ___CSS_LOADER_EXPORT___;
