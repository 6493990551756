// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --guuru-embed-color: #111;
  --guuru-button-border-radius: 0%;
}

.index-social-button-RZOEf {
  display: inline-block;
  position: relative;
  color: #fff;
  background-color: var(--guuru-embed-color);
  border-radius: var(--guuru-button-border-radius);
  box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
  padding: 12px 0;
  min-width: 190px;
  max-width: 340px;
  width: 100%;
  cursor: pointer;
  transition: filter 0.3s ease;
  user-select: none;
}

.index-social-button-RZOEf:hover {
  filter: contrast(85%);
}

.index-button-arrow-TECCT {
  height: 100%;
  position: absolute;
  right: 12px;
  top: 0;
  display: flex;
  place-content: center center;
  flex-direction: column;
}

.index-button-title-_DjvM {
  color: #fff;
  font-weight: 600;
  margin: 0 30px 0 20px !important;
  font-size: 16px;
  line-height: 25px;
}

.index-button-description-TSE5e {
  color: #fff;
  font-weight: 300;
  margin: 0 30px 0 20px !important;
  font-size: 14px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"social-button": `index-social-button-RZOEf`,
	"socialButton": `index-social-button-RZOEf`,
	"button-arrow": `index-button-arrow-TECCT`,
	"buttonArrow": `index-button-arrow-TECCT`,
	"button-title": `index-button-title-_DjvM`,
	"buttonTitle": `index-button-title-_DjvM`,
	"button-description": `index-button-description-TSE5e`,
	"buttonDescription": `index-button-description-TSE5e`
};
export default ___CSS_LOADER_EXPORT___;
