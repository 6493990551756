import LoadFeatures from '../modules/loadFeatures';
import SmartForm from '../modules/smartForm';
import Locale from '../modules/locale';
import Launcher from '../modules/launcher';
import Leads from '../modules/leads';
import Analytics from '../modules/analytics';
import Category from '../modules/category';
import Conversion from '../modules/conversion';
import DisplayRules from '../modules/displayRules';
import WorkingHours from '../modules/workingHours';
import AutoMessages from '../modules/autoMessages';
import Events from '../modules/events';
import Vanilla from './vanilla';
import Spaces from '../modules/spaces';

const defaultSettings = {
  appId: null,
  debug: false,
  isPreview: false,
  currency: undefined,
  value: undefined,
  refererTrigger: 'Chat Button',
  referer: Vanilla.getCanonicalUrl() || window.location.href,
  refererPath: window.location.pathname,
  refererType: '',
  ...Events.defaultSettings,
  ...AutoMessages.defaultSettings,
  ...WorkingHours.defaultSettings,
  ...DisplayRules.defaultSettings,
  ...Conversion.defaultSettings,
  ...Category.defaultSettings,
  ...Analytics.defaultSettings,
  ...Leads.defaultSettings,
  ...Launcher.defaultSettings,
  ...Locale.defaultSettings,
  ...LoadFeatures.defaultSettings,
  ...SmartForm.defaultSettings,
  ...Spaces.defaultSettings,
};

export default defaultSettings;
