import Vanilla from '../helpers/vanilla';
import DynamicSettings from '../helpers/dynamicSettings';
import Settings from '../helpers/settings';

const getPrepared = function () {
  const user = Settings.get('user');
  if (user) {
    Vanilla.debugMessage(`Using user from init ${JSON.stringify(user)}`);
  }
  return !!user;
};

const HELPER = {
  initUser() {
    const hasUser = getPrepared();
    if (!hasUser) return;

    const settings = Settings.getAll();
    const { user } = settings;
    DynamicSettings.add('isUser', true);
    const { name, email } = user;
    if (email) {
      Settings.add('payloadEmail', email.trim());
    }
    if (name) {
      Settings.add('payloadName', name.trim());
    }
  },

};
export default HELPER;
