import Vanilla from '../helpers/vanilla';
import Settings from '../helpers/settings';

const defaultSettings = {
  workingHoursEnabled: false,
};

const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const getDateTime = function (date, options) {
  let intlOption = options;

  // IE11 only supports UTC
  if (window.msCrypto) {
    intlOption = { ...options, timeZone: 'UTC' };
  }

  // IE11 outputs `\u200E` in front of the day of the week for some reason
  return new Intl.DateTimeFormat('en-US', intlOption)
    .format(date)
    .replace(/\u200E/g, '');
};

const getWeekdayInTimezone = function (date, timeZone) {
  const options = { timeZone, weekday: 'long' };
  const weekday = getDateTime(date, options);
  return weekdays.indexOf(weekday) + 1;
};

const getMilitatyTime = function (time) {
  const [hours, minutes] = time.split(':');
  return (hours % 24) * 100 + (minutes % 60);
};

const getTimeInTimezone = function (date, timeZone) {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZone,
  };
  return getMilitatyTime(getDateTime(date, options));
};

const isBetween = function (value, start, end) {
  return value >= start && value <= end;
};

const prepareWorkingHours = function () {
  // Working hours can be initialized with single ranges or array of ranges.
  // In any case, the output should be an array
  const settings = Settings.getAll();
  if (settings.workingHours) {
    const keys = Object.keys(settings.workingHours);
    for (let i = 0; i < keys.length; i += 1) {
      const index = keys[i];
      if (!Array.isArray(settings.workingHours[index])) {
        settings.workingHours[index] = [settings.workingHours[index]];
      }
    }
  }
};

const isTimeWithinTimeRange = function (time, timezone, timeRange) {
  const timeTz = getTimeInTimezone(time, timezone);
  const startTime = getMilitatyTime(timeRange.start);
  const endTime = getMilitatyTime(timeRange.end);
  return isBetween(timeTz, startTime, endTime);
};

const canDisplayChatBasedOnWorkingHours = function (
  {
    workingHours,
    workingHoursTimezone: timezone = 'Europe/Zurich',
  },
  now = new Date(),
) {
  if (workingHours) {
    const weekDay = getWeekdayInTimezone(now, timezone);
    const timeRanges = workingHours[weekDay];
    if (timeRanges) {
      for (let i = 0; i < timeRanges.length; i += 1) {
        if (isTimeWithinTimeRange(now, timezone, timeRanges[i])) {
          return true;
        }
      }
    }
    Vanilla.debugMessage('Chat is not enabled (reason: settings.workingHours).');
    return false;
  }
  return true;
};

const refreshWorkingHoursState = function () {
  const settings = Settings.getAll();
  if (!canDisplayChatBasedOnWorkingHours(settings)) {
    Vanilla.addClass(document.body, 'guuru-chat-nonWorkingHours');
  } else {
    Vanilla.removeClass(document.body, 'guuru-chat-nonWorkingHours');
  }
};

// Initialize working hours timer
const initializeWorkingHours = function (guuruOverrideEnabled) {
  const { workingHours, workingHoursEnabled } = Settings.getAll();
  if (!workingHoursEnabled || guuruOverrideEnabled) {
    return;
  }
  if (workingHours) {
    refreshWorkingHoursState();
    // Set timer
    setInterval(() => {
      refreshWorkingHoursState();
    }, 10000);
  }
};

const HELPER = {
  defaultSettings,
  prepareWorkingHours,
  initializeWorkingHours,
  canDisplayChatBasedOnWorkingHours,
  isTimeWithinTimeRange,
};

export default HELPER;
