import Vanilla from '../helpers/vanilla';
import Settings from '../helpers/settings';
import LoadFeatures from './loadFeatures';
import Analytics from './analytics';
import Locale from './locale';
import Token from './token';
import Listeners from './listeners';
import WorkingHours from './workingHours';
import Launcher from './launcher';
import Events from './events';
import AutoMessages from './autoMessages';
import ChatWidget from './chatWidget';
import Category from './category';
import Leads from './leads';
import PageTitle from './pageTitle';
import User from './user';
import SmartForm from './smartForm';
import DisplayRules from './displayRules';
import Conversion from './conversion';
import Embed from './embed';
import CONSTANTS from '../helpers/constants';
import defaultSettings from '../helpers/defaultSettings';
import Iframe from '../helpers/iframe';
import Storage from '../helpers/storage';
import Spaces from './spaces';

let tempOptions;

const guuruOverrideEnabled = (
  window.location.href.indexOf('guuruOverrideEnabled=true') > -1
);

// The loader can be disabled based on config and display rules
const prepareEnabled = function () {
  const settings = Settings.getAll();
  settings.enabled = settings.launcher.enabled;
  if (!settings.enabled) {
    Vanilla.debugMessage(`Chat is not enabled (settings.enabled=${settings.enabled})`);
  }

  // The loader can be disabled based on days, locale and url
  settings.enabled = settings.enabled
    && DisplayRules.isEnabledByDays()
    && DisplayRules.isEnabledByLocale();
};

// Some parameters can be overriden if settings are passed by parameter
const processOverrideSettings = function () {
  const settings = Settings.getAll();
  if (guuruOverrideEnabled) {
    settings.launcher.enabled = true;
  }
  const guuruDebug = window.location.href.indexOf('guuruDebug=true') > -1;
  if (guuruDebug) {
    settings.debug = true;
  }
};

/**
 * Initializes the chat window according to options passed as parameter and
 * via configuration file (json)
 * Options passed as parameter in init will override json values
 * When some options are missing, then default values will be used.
 */
const initSettings = function (initOptions) {
  const configOptions = Settings.getConfigOptions();
  const withPortalSettings = Vanilla.extend(true, configOptions, initOptions);
  const settings = Vanilla.extend(true, defaultSettings, withPortalSettings);
  Settings.set(settings);
  return settings;
};

const isChatButtonEnabled = function (settings) {
  return settings.enabled
    && Launcher.isEnabledByUrl()
    && WorkingHours.canDisplayChatBasedOnWorkingHours(settings);
};

const load = function (initOptions = {}) {
  const settings = initSettings(initOptions);
  LoadFeatures.prepareLoadFeatures();
  Analytics.init();
  processOverrideSettings();
  Locale.prepareLocale();
  prepareEnabled();
  Token.prepareToken();
  if (settings.enabled) {
    Spaces.initSpaces();
    Listeners.overridePushState();
    WorkingHours.prepareWorkingHours();
    Launcher.prepareLauncher();
    ChatWidget.prepareChatParameters();
    Events.prepareEventsSettings();
    Category.initCategoryMapper();
    Leads.initTrackLeads();
    User.initUser();
    AutoMessages.initInvitationContent();
    AutoMessages.initAutoMessages();
    Launcher.attachChat();
    Listeners.initializeListeners();
    DisplayRules.initDisplayRules();
    Listeners.initializeHideUntilScroll();
    WorkingHours.initializeWorkingHours(guuruOverrideEnabled);
    Launcher.initStickyElements();
    Vanilla.addClass(document.body, 'guuru-chat-initialized');
  }
  Embed.attachEmbeds();
  SmartForm.attachForm();
  Conversion.runConversionGoals();
  Events.processEvent({
    id: `${CONSTANTS.GUURU_EVENT_NAMESPACE}loader:initialized`,
    data: {
      enabled: isChatButtonEnabled(settings),
    },
  });
  if (isChatButtonEnabled(settings) && !DisplayRules.isHideOnLoad()) {
    Analytics.trackEvent('chat_button_shown');
  }
};

const attachLoader = () => {
  const options = tempOptions;
  const script = document.createElement('script');
  script.onload = function () {
    load(options);
  };
  const scriptHost = `https://${process.env.BUCKET_STATIC_DOMAIN}`;
  const loaderEndpoint = options?.isPreview ? 'preview' : 'loader';
  script.src = `${scriptHost}/partners/${options.appId}/${loaderEndpoint}/config.js`;
  script.id = 'guuru-partner-config';
  document.head.appendChild(script);
};

const handlePostMessages = (event) => {
  let { data } = event;
  if (!data) data = event.originalEvent?.data;
  if (event.origin !== process.env.CHAT_ENDPOINT) return null;
  switch (data?.id) {
    case 'chat:addNewMessageNotification':
      PageTitle.handleAddNewMessageNotification(data.data);
      break;
    case 'chat:ready':
      ChatWidget.resolve();
      break;
    default:
      Vanilla.debugMessage('Event received', event);
      break;
  }
  return null;
};

const init = function (options) {
  if (!Storage.isLocalStorageAvailable()) {
    const debugMsg = 'Chat is not enabled (reason: cookies/localStorage not available)';
    Vanilla.warnMessage(debugMsg);
    return;
  }
  if (!Storage.isIndexDBAvailable()) {
    const debugMsg = 'Chat is not enabled (reason: indexedDB not available)';
    Vanilla.warnMessage(debugMsg);
    return;
  }
  tempOptions = options;
  attachLoader();
  Iframe.bindIframeEvent(window, 'message', handlePostMessages);
};

const destroy = function () {
  ChatWidget.setIsIframeInitialized(false);
  ChatWidget.hideChat();
  Listeners.removeWindowListeners();
  [
    '#GuuruMain',
    '#guuru-partner-config',
    '#guuru-launcher-styling',
    '#guuru-analytics',
  ].forEach((selector) => {
    const element = document.querySelector(selector);
    if (element) {
      element.remove();
    }
  });
};

const HELPER = {
  init,
  load,
  destroy,
  isChatButtonEnabled,
};
export default HELPER;
