import Vanilla from '../../helpers/vanilla';
import Storage from '../../helpers/storage';
import CONSTANTS from '../../helpers/constants';
import DynamicSettings from '../../helpers/dynamicSettings';
import Launcher from '../launcher';
import WorkingHours from '../workingHours';
import Settings from '../../helpers/settings';
import Grabber from '../grabber';
import AutoMessagesStorage from './storage';

const {
  PROACTIVE_CHAT_OPEN_ACTION,
  PROACTIVE_CHAT_INVITATION_GRABBER_ACTION,
  PROACTIVE_CHAT_ATTENTION_GRABBER_ACTION,
  PROACTIVE_CHAT_SIGNAL_ACTION,
} = CONSTANTS;

const defaultSettings = {
  autoMessages: [],
};

const HELPER = {
  defaultSettings,

  current: {
    signal: null,
  },

  timers: {
    showSignalBadge: null,
  },

  actions: {
    hasAction: (action, value) => action === value,

    isActionOpenChat: (action) => (
      HELPER.actions.hasAction(action, PROACTIVE_CHAT_OPEN_ACTION)
    ),

    isActionGrabber: (action) => ([
      PROACTIVE_CHAT_ATTENTION_GRABBER_ACTION,
      PROACTIVE_CHAT_INVITATION_GRABBER_ACTION,
    ].includes(action)),

    isActionShowSignal: (action) => (
      HELPER.actions.hasAction(action, PROACTIVE_CHAT_SIGNAL_ACTION)
    ),
  },

  getPrepared() {
    const { loadFeatures: { proactive } = {}, isPreview } = Settings.getAll();
    if (isPreview) {
      Storage.removeItem(CONSTANTS.PREVENT_PROACTIVE_CHAT);
    }
    if (!proactive) {
      AutoMessagesStorage.clearStorage();
      Vanilla.debugMessage(`Proactive chat is not enabled (reason: settings.loadFeatures.proactive=${proactive})`);
      return false;
    }
    return true;
  },

  // Finds autoMessages that match all criteria
  filterMatchingAutoMessages: (
    autoMessages,
    settings,
  ) => autoMessages.filter((autoMessage) => {
    const {
      id,
      action,
      url,
      category,
      numberOfVisits,
      referrerUrl,
      targetDevices,
    } = autoMessage;

    if (action === 'communityLoader') {
      return false;
    }

    if (
      action === 'quickQuestions'
        && Vanilla.hasPageTargeting(settings, url, category)
        && Vanilla.isForCurrentDeviceTarget(targetDevices)
    ) {
      // Send auto message id to chat service, where we want to show
      // the quick questions auto message type
      DynamicSettings.add('chatAutoMessageId', id);
      return false;
    }

    let visits = AutoMessagesStorage.getAutoMessage(id).visits || 0;
    if ((visits < numberOfVisits)
      && (!referrerUrl || HELPER.hasReferrer(referrerUrl))) {
      visits = HELPER.setNumberOfVisits(autoMessage, numberOfVisits);
    }

    return Vanilla.isForCurrentDeviceTarget(targetDevices)
        && Vanilla.hasPageTargeting(settings, url, category)
        && !AutoMessagesStorage.isAutoMessageRead(id)
        && (!numberOfVisits || visits >= numberOfVisits)
        && (!referrerUrl || HELPER.hasReferrer(referrerUrl));
  }),

  prioritizeAutoMessage: (messages) => messages.reduce((acc, message) => {
    const msgWithSameAction = acc.find((msg) => (
      msg.action === message.action
    ));
    if (msgWithSameAction) {
      if (msgWithSameAction.url && !message.url) {
        return acc;
      }
      if ((msgWithSameAction.timeInPage >= message.timeInPage)
          || ((!msgWithSameAction.url && message.url)
            || (!msgWithSameAction.referrerUrl && message.referrerUrl)
          )) {
        acc.splice(acc.indexOf(msgWithSameAction), 1);
        return [...acc, message];
      }
    } else {
      return [...acc, message];
    }
    return acc;
  }, []),

  setupAutoMessages(autoMessages, settings) {
    const messages = HELPER.filterMatchingAutoMessages(autoMessages, settings);

    if (messages.length) {
      const messagesFiltered = HELPER.prioritizeAutoMessage(messages);
      messagesFiltered
        .sort((a, b) => a.timeInPage - b.timeInPage)
        .forEach((msg) => {
          const { content } = msg;
          HELPER.scheduleAction(
            {
              ...msg,
              content: content[settings.userLocale || settings.locale],
            },
          );
        });
    }
  },

  initInvitationContent() {
    const settings = Settings.getAll();
    const { autoMessages } = settings;
    const invitationGrabber = autoMessages.find(
      ({ action }) => action === 'invitationGrabber',
    );

    if (!invitationGrabber) {
      Vanilla.debugMessage('Invitation Box not enabled');
      return;
    }

    const { content, enabledLanguages } = invitationGrabber;

    const languages = enabledLanguages?.length > 0
      ? enabledLanguages
      : Object.keys(content);

    const userLocale = languages.find((locale) => (
      settings.locale === locale
    ));

    if (userLocale) {
      HELPER.setupAutoMessages([invitationGrabber], {
        ...settings,
        userLocale,
      });
    }
  },

  initAutoMessages() {
    const isEnabled = HELPER.getPrepared();
    const settings = Settings.getAll();
    if (
      !isEnabled
        || !Launcher.isEnabledByUrl()
        || !WorkingHours.canDisplayChatBasedOnWorkingHours(settings)
    ) return;
    const { autoMessages } = settings;
    if (!autoMessages || !autoMessages.length) {
      Storage.removeItem(CONSTANTS.PROACTIVE_CHATS);
      return;
    }
    AutoMessagesStorage.prepareAutoMessagesStored(autoMessages);
    HELPER.setupAutoMessages(
      autoMessages.filter(({ action }) => action !== 'invitationGrabber'),
      settings,
    );
  },

  scheduleAction(autoMessage) {
    const { timeInPage } = autoMessage;
    setTimeout(() => {
      HELPER.showActionByTarget(autoMessage);
    }, timeInPage * 1000);
  },

  hasReferrer(referrerUrl) {
    const { referrer } = window.document;
    const currentReferrer = new RegExp(referrerUrl, 'gi');
    return !!(referrer && !!referrer.match(currentReferrer));
  },

  setNumberOfVisits(autoMessage, numberOfVisits) {
    const { id } = autoMessage;
    const messageStored = AutoMessagesStorage.getAutoMessage(id);

    const currentVisits = messageStored && messageStored.visits;
    const visits = (!!currentVisits && !!numberOfVisits)
      ? currentVisits + 1
      : 1;
    AutoMessagesStorage
      .handleAutoMessageStore(autoMessage, { read: false, visits });

    return visits;
  },

  // Remove the id from the settings if the message was already read
  cleanUpReadId(id) {
    if (AutoMessagesStorage.settings.ids.includes(id)) {
      const index = AutoMessagesStorage.settings.ids.indexOf(id);
      AutoMessagesStorage.settings.ids.splice(index, 1);
    }
  },

  showGrabber(autoMessage) {
    const {
      id,
      title,
      action,
      content,
      duration,
      targetDevices,
    } = autoMessage;

    const isAutoMessageRead = AutoMessagesStorage.isAutoMessageRead(id);
    if (!isAutoMessageRead && id) {
      if (!HELPER.chatPreviouslyOpen() && window.guuru.isChatClosed()) {
        const preventChat = Storage.getItem(CONSTANTS.PREVENT_PROACTIVE_CHAT);
        if (!preventChat) {
          const grabberDisplayed = Grabber.launch({
            id,
            title,
            action,
            content,
            targetDevices,
          });
          if (grabberDisplayed) {
            Grabber.setCurrentGrabber(autoMessage);
            Grabber.setupGrabberAutoHide(duration);
          }
        }
        // Store attention grabber on localstorage
        AutoMessagesStorage.handleAutoMessageStore(autoMessage);
        return;
      }
    }
    HELPER.cleanUpReadId(id);
  },

  // SIGNAL MESSAGE LOGIC

  // it will show a badge on the top of the chat button to emphasize
  // that the user has an unread message
  showSignal(autoMessage) {
    const { id } = autoMessage;
    const isAutoMessageRead = AutoMessagesStorage.isAutoMessageRead(id);
    if (!isAutoMessageRead) {
      if (!HELPER.chatPreviouslyOpen() && window.guuru.isChatClosed()) {
        const preventChat = Storage.getItem(CONSTANTS.PREVENT_PROACTIVE_CHAT);

        if (!preventChat) {
          const launcher = document.querySelector(CONSTANTS.SELECTOR.launcher);
          Vanilla.addClass(launcher, 'guuru-launcher-badge');
          this.current.signal = autoMessage;
        }
        AutoMessagesStorage.handleAutoMessageStore(autoMessage);
        return;
      }
    }
    HELPER.cleanUpReadId(id);
  },

  hideSignal() {
    const launcher = document.querySelector(CONSTANTS.SELECTOR.launcher);
    Vanilla.removeClass(launcher, 'guuru-launcher-badge');
  },

  isSignalVisible() {
    const launcher = document.querySelector(CONSTANTS.SELECTOR.launcher);
    return launcher?.classList.contains('guuru-launcher-badge');
  },

  // OPEN CHAT LOGIC
  openChat(autoMessage) {
    const { id, action, title } = autoMessage;
    const isAutoMessageRead = AutoMessagesStorage.isAutoMessageRead(id);
    if (!isAutoMessageRead) {
      if (!HELPER.chatPreviouslyOpen()) {
        const preventChat = Storage.getItem(CONSTANTS.PREVENT_PROACTIVE_CHAT);
        if (!preventChat) {
          if (action === CONSTANTS.PROACTIVE_CHAT_OPEN_ACTION) {
            DynamicSettings.add('noFocus', true);
          }
          Settings.add('refererTrigger', title);
          window.guuru.openChat();
        }
        AutoMessagesStorage.handleAutoMessageStore(autoMessage);
        return;
      }
    }
    HELPER.cleanUpReadId(id);
  },

  chatPreviouslyOpen() {
    return Storage.getItem(CONSTANTS.CHAT_LOADER_OPEN);
  },

  showActionByTarget(autoMessage) {
    const { action } = autoMessage;

    if (HELPER.chatPreviouslyOpen()) {
      return;
    }

    if (HELPER.actions.isActionGrabber(action)) {
      HELPER.showGrabber(autoMessage);
    }

    if (HELPER.actions.isActionShowSignal(action)) {
      HELPER.showSignal(autoMessage);
    }
    if (HELPER.actions.isActionOpenChat(action)) {
      HELPER.openChat(autoMessage);
    }
  },

  preventAutoMessages() {
    const { autoMessages, loadFeatures } = Settings.getAll();
    if (!loadFeatures?.proactive) return;
    Storage.setItem(CONSTANTS.PREVENT_PROACTIVE_CHAT, Date.now());
    autoMessages.forEach((msg) => {
      AutoMessagesStorage.handleAutoMessageStore(msg, { read: true });
    });
  },
};

export default HELPER;
