// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-embed-headline-hvyOl {
  h3 {
    line-height: 1 !important;
    font-weight: bold !important;
    font-size: 22px !important;
    margin: 0 0 12px !important;
    padding: 0 !important;
    text-align: left;
  }

  p {
    margin: 5px 0 16px;
    font-size: 15px;

    img {
      width: 18px;
      height: 18px;
      vertical-align: middle;
      margin: 0 5px 5px 0;
      display: inline;
    }
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"embed-headline": `index-embed-headline-hvyOl`,
	"embedHeadline": `index-embed-headline-hvyOl`
};
export default ___CSS_LOADER_EXPORT___;
