import client from '../client';
import Vanilla from './vanilla';

const pagination = (query, variables, path) => {
  const allEdges = [];
  let pageInfo = {};
  let count;

  const fetch = async (first) => {
    try {
      let vars = {
        ...variables,
        after: pageInfo?.endCursor,
        isFirstPage: !pageInfo?.endCursor,
      };

      if (first) {
        vars = { ...vars, first };
      }

      const result = await client.query(query, vars);

      if (result?.error) {
        throw new Error(result.error);
      }

      const edgesAndPageInfo = Vanilla.getValue(result?.data, path, null);
      if (!edgesAndPageInfo) {
        return { data: [] };
      }

      allEdges.push(...edgesAndPageInfo.edges);
      count = edgesAndPageInfo.count;
      pageInfo = edgesAndPageInfo.pageInfo;

      return { data: allEdges };
    } catch (e) {
      Vanilla.debugMessage(e, '- Paginated Query');
      return { data: [] };
    }
  };

  const getDataInMemory = () => allEdges;

  const hasMorePages = () => pageInfo?.hasNextPage;

  const getCount = () => count;

  return {
    fetch,
    getDataInMemory,
    hasMorePages,
    getCount,
  };
};

export default pagination;
