import styles from './index.css';
import Storage from '../../../../../../helpers/storage';
import Analytics from '../../../../../analytics';
import Vanilla from '../../../../../../helpers/vanilla';
import Events from '../../../../../events';
import Constants from '../../../../../../helpers/constants';

const CONTENT_FEEDBACKS = 'contentFeedbacks';

const removeDisabled = (el) => {
  const feedbackInputs = el.parentElement;
  [...feedbackInputs.getElementsByClassName('content-feedback')]
    .map((type) => type.removeAttribute('disabled'));
};

const handleFeedbackClick = (el) => {
  const value = el.dataset.guuruValue;
  const id = el.dataset.guuruId;
  const type = value ? 'positive' : 'negative';
  Storage.setItem(Number(id), Number(value), CONTENT_FEEDBACKS);
  Analytics.trackEvent('content_embed_interaction', type, { content_id: id });
  Events.processEvent({
    id: `${Constants.GUURU_EVENT_NAMESPACE}embed:interaction`,
    data: { type: 'content', element: type },
  });
  removeDisabled(el);
  el.setAttribute('disabled', true);
};

const initializeFeedbackButtonsListeners = () => {
  Vanilla.live('.content-feedback', ['click'], (el) => {
    handleFeedbackClick(el.target);
  });
};

const renderFeedbackButtons = function ({ locale = 'en', id }) {
  const savedFeedback = Storage.getItem(CONTENT_FEEDBACKS)?.[id];
  const {
    helpful,
    yes,
    no,
  } = locale === 'de'
    ? { helpful: 'Hilfreich?', yes: 'Ja', no: 'Nein' }
    : { helpful: 'Helpful?', yes: 'Yes', no: 'No' };

  return `
    <div class="${styles.contentFeedback}">
      <p>|</p>
      <p>${helpful}</p>
      <div class="${styles.contentFeedbackButtons}">
        <input
          ${savedFeedback === 1 ? 'disabled' : ''}
          data-guuru-id="${id}"
          data-guuru-value="1"
          class="content-feedback"
          alt="Like input"
          title="${yes}"
          type="image"
          src="https://cdn.guuru.com/assets/icons/like.svg"
        />
        <input
          ${savedFeedback === 0 ? 'disabled' : ''}
          data-guuru-id="${id}"
          data-guuru-value="0"
          class="content-feedback"
          alt="Dislike input"
          title="${no}"
          type="image"
          title="No"
          src="https://cdn.guuru.com/assets/icons/dislike.svg"
        />
      </div>
    </div>
  `;
};

export {
  renderFeedbackButtons,
  initializeFeedbackButtonsListeners,
};
