import styles from './index.css';
import { heroes } from '../../components';

const getLauncherSettings = (settings, communityLoaderAutoMessage) => {
  let launcherContent;
  let launcherContentMobile;
  let launcherHover;
  let launcherBorderRadius;
  const { locale } = settings;
  if (communityLoaderAutoMessage) {
    const { content } = communityLoaderAutoMessage;
    const [
      text, textHover, textMobile, borderRadius,
    ] = content[locale].split('\n');
    launcherContent = text;
    launcherContentMobile = textMobile;
    launcherHover = textHover;
    launcherBorderRadius = borderRadius || settings.launcher.borderRadius;
  } else {
    const {
      text,
      textMobile,
      textHover,
      borderRadius,
    } = settings.launcher;
    launcherContent = text[locale];
    launcherContentMobile = textMobile[locale];
    launcherHover = textHover[locale];
    launcherBorderRadius = borderRadius;
  }
  return {
    channel: 'community',
    launcherFormat: 'community',
    launcherPictures: settings.launcher.pictures,
    launcherContent,
    launcherContentMobile,
    launcherHover,
    launcherBorderRadius,
  };
};

const getLauncherButton = function (settings) {
  const {
    launcherPictures,
    launcherContent,
    launcherContentMobile,
    launcherHover,
    launcherTextColor,
  } = settings;

  const avatars = launcherPictures?.sort(() => 0.5 - Math.random()).slice(0, 3);
  const titleClasses = !launcherContent?.trim().length ? styles.hideTitle : '';
  const mobileTitleClasses = !launcherContentMobile?.trim().length ? styles.hideTitle : '';
  const titleStyles = `color: ${launcherTextColor}`;
  return `
    <div class="${styles.launcherCommunity}"> \
      ${heroes({ avatars, width: 42 })}
      <div class="${styles.title} ${styles.launcherTitle} ${titleClasses}"> \
        <span style='${titleStyles}'>${launcherContent ?? ''}</span>
      </div>
      <div class="${styles.title} ${styles.launcherTitleSmall} ${mobileTitleClasses}"> \
        <span style='${titleStyles}'>${launcherContentMobile ?? ''}</span>
      </div>
      <div class=${styles.details}> \
        <span style='${titleStyles}'>${launcherHover ?? ''}</span>
      </div>
    </div>
    `;
};

const HELPER = {
  getLauncherButton,
  getLauncherSettings,
};

export default HELPER;
