import styles from './index.css';

const hero = (url, width) => (
  `<div
    class="${styles.hero} hero-avatar"
    style="background-image: url(${url}); width: ${width}px; height: ${width}px"
  ></div>`
);

export default function ({ avatars, width }) {
  const greenDotPosition = width >= 64
    ? styles.greenDotBig
    : styles.greenDotSmall;
  return `<div class="${styles.heroes} heroes" style="max-height: ${width}px"> \
    ${avatars?.map((url) => hero(url, width)).join('')}
    <div aria-hidden="true" class="${styles.greenDot} ${greenDotPosition} green-dot"></div>\
  </div>`;
}
