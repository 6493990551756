// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-powered-by-Hijh7 {
    display: flex;
    justify-content: center;
}

.index-powered-by-Hijh7 a {
    color: #1e1e1e;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    margin-top: 8px;
    font-size: 9px;
    font-weight: 300;
    text-decoration: none;
    border-radius: 14px;
    -webkit-font-smoothing: subpixel-antialiased;
    padding: 8px 12px;

    &:hover {
      color: rgb(0 0 0);
      background-color: rgba(0 0 0 / 3%);
    }
}

.index-powered-by-Hijh7 img {
  width: 12px;
  margin-right: 6px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"powered-by": `index-powered-by-Hijh7`,
	"poweredBy": `index-powered-by-Hijh7`
};
export default ___CSS_LOADER_EXPORT___;
