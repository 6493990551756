import Vanilla from '../helpers/vanilla';
import CONSTANTS from '../helpers/constants';
import Settings from '../helpers/settings';

const defaultSettings = {
  locale: 'en',
};

const prepareLocale = function () {
  const settings = Settings.getAll();
  const { locale } = settings;

  const [language, country] = locale.split('-');

  settings.locale = language.toLowerCase();
  if (country) {
    settings.payloadUserLocale = country.toLowerCase();
  }

  if (!Vanilla.contains(CONSTANTS.SUPPORTED_LOCALES, settings.locale)) {
    throw new Error(CONSTANTS.VALIDATION.LOCALES);
  }
};

const HELPER = {
  defaultSettings,
  prepareLocale,
};

export default HELPER;
