import { gtag } from '@guuru/analytics-web';
import Vanilla from '../helpers/vanilla';
import Settings from '../helpers/settings';

const defaultSettings = {
  analyticsEnabled: true,
};

const measurementId = `${process.env.CHAT_GA_MEASUREMENT_ID}`;

const getPrepared = function () {
  const settings = Settings.getAll();
  const { analyticsEnabled, loadFeatures = {} } = settings;
  settings.analyticsEnabled = (
    analyticsEnabled || loadFeatures.analytics
  );
  if (!settings.analyticsEnabled) {
    document.cookie = 'guuruGa_ga= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    document.cookie = `guuruGa_ga_${measurementId?.split('-')[1]}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
    Vanilla.debugMessage(`Analytics is not enabled (reason: settings.analyticsEnabled=${analyticsEnabled} and settings.loadFeatures.analytics=${loadFeatures.analytics} )`);
    return false;
  }

  return true;
};

const HELPER = {
  defaultSettings,
  init() {
    const isEnabled = getPrepared();
    if (!isEnabled) return;

    const { appId, locale, category } = Settings.getAll();

    gtag.initialize(measurementId, {
      cookie_domain: 'none',
      cookie_prefix: 'guuruGa',
      cookie_expires: 31556926, // 12 months expiration time
      send_page_view: false,
      allow_ad_personalization_signals: false,
      allow_google_signals: false,
      debug_mode: process.env.ANALYTICS_DEBUG,
    });
    gtag.setProperties({
      locale,
      partner: appId,
      chatCategory: category,
    });

    gtag.event('page_view', {
      page_path: '/user:chat-loaded',
      locale,
      partner: appId,
      chat_category: category,
      event_category: 'chat_loader',
    });
  },

  trackEvent(eventName, label, args) {
    gtag.track(label, 'chat_loader', eventName, { args });
  },
};
export default HELPER;
