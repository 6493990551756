import Storage from '../../helpers/storage';
import CONSTANTS from '../../helpers/constants';
import Vanilla from '../../helpers/vanilla';

const AutoMessagesStorage = {
  settings: {
    ids: [],
  },
  prepareAutoMessagesStored: (autoMessages = []) => {
    const storedMessages = Storage.getItem(CONSTANTS.PROACTIVE_CHATS);
    const storedMessagesIds = Object.keys(storedMessages || {})
      .map((id) => Number(id));
    storedMessagesIds.forEach((storedMessageId) => {
      AutoMessagesStorage.cleanUpMessage(autoMessages, storedMessageId);
    });
  },

  handleAutoMessageStore: (autoMessage, { read = false, visits } = {}) => {
    const { id, numberOfVisits } = autoMessage;
    const storedAutoMessage = AutoMessagesStorage.getAutoMessage(id);
    const preventChat = Storage.getItem(CONSTANTS.PREVENT_PROACTIVE_CHAT);

    if (!storedAutoMessage) {
      AutoMessagesStorage.storeAutoMessage(autoMessage, { read, visits });
      // To correctly check the message as read,
      // the message needs be stored first
      if (preventChat) {
        AutoMessagesStorage.setAutoMessageAsRead(id);
      }
    } else {
      AutoMessagesStorage
        .updateStoredAutoMessage(autoMessage, { read, visits });
    }

    // Save id on settings
    if (!numberOfVisits || (numberOfVisits === visits)) {
      if (!AutoMessagesStorage.settings.ids.includes(id)) {
        AutoMessagesStorage.settings.ids.push(Number(id));
      }
    }
  },

  storeAutoMessage: (autoMessage, { read, visits } = {}) => {
    const { id, action } = autoMessage;

    Storage.setItem(
      Number(id),
      {
        action,
        read,
        visits,
        timestamp: Date.now(),
      },
      CONSTANTS.PROACTIVE_CHATS,
    );
  },

  updateStoredAutoMessage: (autoMessage, { read, visits } = {}) => {
    const { id } = autoMessage;
    const autoMessageStored = AutoMessagesStorage.getAutoMessage(id);

    let value = {
      ...autoMessageStored,
      read,
    };
    if (visits) {
      value = { ...value, visits };
    }
    Storage.setItem(id, value, CONSTANTS.PROACTIVE_CHATS);
  },

  // the message should be marked as read,
  // just if the user has seen the message
  setAutoMessageAsRead: (id) => {
    const autoMessageStored = AutoMessagesStorage.getAutoMessage(id);
    return Storage.setItem(
      id,
      { ...autoMessageStored, read: true },
      CONSTANTS.PROACTIVE_CHATS,
    );
  },

  getAutoMessage: (id) => (
    Storage.getItem(id, CONSTANTS.PROACTIVE_CHATS) || false
  ),

  filterStoredMessagesByProp: (messagesStored, prop, value) => (
    Object.keys(messagesStored)
      .reduce((acc, message) => {
        if (messagesStored[message][prop] === value) {
          acc[message] = messagesStored[message];
          return acc;
        }
        return {};
      }, {})
  ),

  getMessagesByAction: (action) => {
    const messages = Storage.getItem(CONSTANTS.PROACTIVE_CHATS) || {};
    return AutoMessagesStorage.filterStoredMessagesByProp(messages, 'action', action);
  },

  getUnreadMessagesByAction: (action) => {
    const messages = AutoMessagesStorage.getMessagesByAction(action);
    return AutoMessagesStorage.filterStoredMessagesByProp(messages, 'read', false);
  },

  // checks if the message was already read
  isAutoMessageRead: (id) => (
    AutoMessagesStorage.getAutoMessage(id).read === true),

  removeAutoMessage: (id) => {
    Storage.removeItem(id, CONSTANTS.PROACTIVE_CHATS);

    // Remove full namespace when empty
    const messagesStored = Storage.getItem(CONSTANTS.PROACTIVE_CHATS);
    if (Vanilla.getObjectLength(messagesStored) === 1) {
      Storage.removeItem(CONSTANTS.PROACTIVE_CHATS);
    }
  },

  chatPreviouslyOpen() {
    return Storage.getItem(CONSTANTS.CHAT_LOADER_OPEN);
  },

  // Clean up the chatLoaderOpen when the message is updated on the
  // partner portal to prevent misleads, because if the chat is open,
  // the default behaviour is to hide the attention grabber/signal
  clearChatLoaderOpen: (timestamp) => {
    if (timestamp > AutoMessagesStorage.chatPreviouslyOpen()) {
      Storage.removeItem(CONSTANTS.CHAT_LOADER_OPEN);
    }
  },

  // CLEAN UP LOGIC
  // Verifies what messages are on the settings and remove the ones
  // that have been removed or updated and the ones that should
  // restart after the display rate have passed
  cleanUpMessage: (autoMessages, storedMsgId) => {
    const autoMessage = autoMessages.find(({ id }) => (id === storedMsgId));

    if (!autoMessage) {
      AutoMessagesStorage.clearStorageByDeletedMessages(storedMsgId);
      // If a new proactive chat is enabled,
      // clear the prevent storage and the chat loader open
      Storage.removeItem(CONSTANTS.PREVENT_PROACTIVE_CHAT);
      Storage.removeItem(CONSTANTS.CHAT_LOADER_OPEN);
      return;
    }
    AutoMessagesStorage.clearStorageByUpdatedMessages(autoMessage);
    AutoMessagesStorage.clearStorageByDisplayRate(autoMessage);
  },

  clearStorageByDeletedMessages: (storedMessageId) => {
    AutoMessagesStorage.removeAutoMessage(storedMessageId);
  },

  clearStorageByUpdatedMessages: (autoMessage) => {
    const { updatedAt, id, action } = autoMessage;
    const storedAutoMessage = AutoMessagesStorage.getAutoMessage(id);
    const lastTimeUpdated = Date.parse(updatedAt);
    const actionHasChanged = action !== storedAutoMessage.action;
    const timeHasPassed = lastTimeUpdated > storedAutoMessage.timestamp;
    if (actionHasChanged || timeHasPassed) {
      AutoMessagesStorage.removeAutoMessage(id);
      AutoMessagesStorage.clearChatLoaderOpen(lastTimeUpdated);
      // Clear prevent proactive chat, to prevent misleads when the message
      // is updated on the partner portal
      Storage.removeItem(CONSTANTS.PREVENT_PROACTIVE_CHAT);
    }
  },

  // if the defined display rate has already passed, the message
  // will be cleared from the local storage
  clearStorageByDisplayRate: (autoMessage) => {
    const { id, displayRate } = autoMessage;
    const autoMessageStored = (
      AutoMessagesStorage.getAutoMessage(id)
    );
    if (autoMessageStored && displayRate >= 0) {
      const isRead = autoMessageStored.read === true;
      const diffInMin = Vanilla.timeMinutesDiff(
        autoMessageStored.timestamp,
        new Date().getTime(),
      );
      const cleanUp = diffInMin > (displayRate / 60);
      if (Number.isNaN(autoMessageStored.timestamp) || (cleanUp && isRead)) {
        Storage.removeItem(CONSTANTS.PREVENT_PROACTIVE_CHAT);
        AutoMessagesStorage.removeAutoMessage(id);
      }
    }
  },

  clearStorage: () => {
    const { autoMessages = {} } = Storage.getAll();
    if (autoMessages) {
      AutoMessagesStorage.prepareAutoMessagesStored([autoMessages]);
    }
    Storage.removeItem(CONSTANTS.PREVENT_PROACTIVE_CHAT);
  },
};

export default AutoMessagesStorage;
