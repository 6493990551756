import CONSTANTS from '../../helpers/constants';
import Vanilla from '../../helpers/vanilla';
import Attention from './attention';
import Invitation from './invitation';
import Analytics from '../analytics';
import AutoMessagesStorage from '../autoMessages/storage';
import styles from './index.css';

const timers = {
  closeGrabber: null,
};

let currentGrabber = '';

const {
  SELECTOR: {
    grabber: grabberClass,
  },
  STYLES: {
    ATTENTION_GRABBER,
  },
} = CONSTANTS;

const types = {
  attentionGrabber: Attention,
  invitationGrabber: Invitation,
};

const getHtml = (classname) => (
  document.querySelector(classname)
);

const addGrabberDeviceClasses = ({ targetDevices, grabberHtml }) => (
  targetDevices.forEach((device) => (
    Vanilla.addClass(grabberHtml, ATTENTION_GRABBER[device])
  ))
);

const animateGrabberShow = (grabberHtml) => (
  Vanilla.addClass(grabberHtml, styles.fadeIn)
);

const animateGrabberHide = (grabberHtml) => (
  Vanilla.removeClass(grabberHtml, styles.fadeIn)
);

const renderCloseButtonHtml = () => `
  <div class='${styles.grabberOptions}'>
    <i class='js-guuru-launcher-grabber-close ${styles.grabberClose}' aria-hidden='true'>
      ${CONSTANTS.ICONS.close}
    </i>
  </div>
`;

const attachCustomStyles = (Type) => {
  const css = Type.getStyles();
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.id = 'guuru-launcher-styling';
  style.type = 'text/css';

  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }

  head.appendChild(style);
};

const setVisible = () => {
  const grabber = getHtml(grabberClass);
  if (grabber) {
    animateGrabberShow(grabber);
  }
};

const isVisible = () => {
  const grabber = getHtml(grabberClass);
  return grabber?.classList.contains(styles.fadeIn);
};

const hide = () => {
  const grabber = getHtml(grabberClass);
  if (grabber) {
    grabber.addEventListener('click', this.hideImmediately);

    const schedule = () => {
      if (grabber.matches(':hover')) {
        setTimeout(schedule, 1000);
      } else {
        animateGrabberHide(grabber);
      }
    };
    schedule();
  }
  window.clearTimeout(timers.closeGrabber);
};

const hideImmediately = () => {
  const grabber = getHtml(grabberClass);
  if (grabber) {
    animateGrabberHide(grabber);
  }
};

const trackCloseEvent = () => {
  const { action } = currentGrabber;
  const evt = action === 'attentionGrabber'
    ? 'attention_grabber_close_click'
    : 'invitation_grabber_close_click';
  Analytics.trackEvent(evt);
};

// hide grabber after a specify time or
// if any chat interaction has occurred
const setupGrabberAutoHide = (duration) => {
  window.clearTimeout(timers.closeGrabber);
  timers.closeGrabber = setTimeout(() => {
    hide();
  }, duration * 1000);
};

const handleCloseClick = (event) => {
  event.stopPropagation();
  const { id } = currentGrabber;
  AutoMessagesStorage.setAutoMessageAsRead(id);
  hideImmediately();
  trackCloseEvent();
};

const handleLauncherLeave = () => {
  const { action } = currentGrabber;
  const isAttentionGrabber = action === 'attentionGrabber';
  if (isAttentionGrabber && isVisible()) {
    setupGrabberAutoHide(0.2);
  }
};

const handleLauncherEnter = () => {
  const { action } = currentGrabber;
  const isAttentionGrabber = action === 'attentionGrabber';
  if (isAttentionGrabber && !isVisible() && !window.guuru.isChatOpened()) {
    setVisible();
  }
};

const attachEventListeners = () => {
  Vanilla.addEvents(
    document.querySelector(CONSTANTS.SELECTOR.grabberClose),
    ['click', 'touchend'],
    handleCloseClick,
  );
  document.querySelector(CONSTANTS.SELECTOR.launcher)
    .addEventListener('mouseenter', handleLauncherEnter);
  document.querySelector(CONSTANTS.SELECTOR.launcher)
    .addEventListener('mouseleave', handleLauncherLeave);
};

const addGrabberContent = (content) => {
  const grabberWrapper = document.getElementById('js-guuru-launcher-grabber-wrapper');
  grabberWrapper.innerHTML = content;
};

const Grabber = {
  isVisible,
  hide,
  hideImmediately,
  setupGrabberAutoHide,

  getGrabberHtml: (settings, mods) => {
    const grabberClasses = `js-guuru-launcher-grabber ${mods}`;

    const isCanyon = settings.appId === 'canyon-de';

    return `
      <div class='${`${grabberClasses} ${styles.grabber} ${isCanyon ? styles.positionLeft : undefined}`}'>
        ${renderCloseButtonHtml()}
        <div id='js-guuru-launcher-grabber-wrapper'></div>
      </div>`;
  },

  launch: ({
    id,
    title,
    action,
    content,
    targetDevices,
  }) => {
    if (!content) return false;

    const grabberHtml = getHtml(grabberClass);
    if (!grabberHtml) return false;

    if (Invitation.isVisible()) {
      return false;
    }

    const GrabberInstance = types[action];

    attachEventListeners();
    attachCustomStyles(GrabberInstance);
    addGrabberContent(GrabberInstance.getHtml(content));
    addGrabberDeviceClasses({ targetDevices, grabberHtml });

    animateGrabberShow(grabberHtml);
    GrabberInstance.onLoad({ id, title });

    return true;
  },

  setCurrentGrabber: (grabber) => { currentGrabber = grabber; },

  getCurrentGrabber: () => currentGrabber,
};

export default Grabber;
