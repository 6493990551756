import Storage from '../helpers/storage';
import Vanilla from '../helpers/vanilla';
import Settings from '../helpers/settings';

// Get Token from urlParams/Storage or options -> prefer the second one
const prepareToken = function () {
  if (window.location.href.indexOf('guuruTokenClear=true') > -1) {
    Storage.setItem('token', undefined);
    return;
  }

  let token = Vanilla.getParameterByName('guuruToken');

  if (token) {
    Storage.setItem('token', token);
  } else {
    token = Storage.getItem('token');
  }

  const settings = Settings.getAll();
  settings.token = settings.token || token;
};

const HELPER = {
  prepareToken,
};

export default HELPER;
