// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-content-embed-empty-text-wrapper-Wk_u2 {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: bold;

  span {
    font-size: 16px;
  }

  div {
    margin-top: 30px;
  }
}


`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-embed-empty-text-wrapper": `index-content-embed-empty-text-wrapper-Wk_u2`,
	"contentEmbedEmptyTextWrapper": `index-content-embed-empty-text-wrapper-Wk_u2`
};
export default ___CSS_LOADER_EXPORT___;
