const dynamicSettings = {};

const HELPER = {
  add(setting, value) {
    dynamicSettings[setting] = value;
  },
  getAll() {
    return dynamicSettings;
  },
};

export default HELPER;
