// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable selector-id-pattern, selector-class-pattern */
/* stylelint-disable keyframes-name-pattern */
/* stylelint-disable selector-descendant-combinator-no-non-space */

@keyframes expand {
  from {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes open {
  from {
    transform: translateY(10%);
    opacity: 0;
  }
}

.guuru-launcher-active-badge {
  background-color: #52D191;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
  float: right;
  position: relative;
  box-shadow: 0 0 0 1px #fff;
}

.guuru-launcher {
  display: none;
  position: fixed;
  cursor: pointer;
  z-index: 1001;
}

.guuru-launcher-button {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 1px 6px rgba(0 0 0 / 6%), 0 2px 32px rgba(0 0 0 / 16%);
  animation: expand 0.3s ease-in-out;
}

.guuru-launcher-button.mod--transparent {
  box-shadow: none;
  background-color: transparent;
}

.guuru-launcher-button.guuru-launcher-badge {
  overflow: visible;
}

.guuru-launcher-button::after {
  content: none;
  position: absolute;
  top: -2px;
  right: -2px;
  font-family: Roboto, sans-serif !important;
  font-size: 9px;
  background: #dd4a4a;
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  box-shadow: 0 0 2px #33333373;
}

.guuru-launcher-button::after.mod--transparent {
  box-shadow: none;
}

.guuru-launcher-badge::after {
  content: "1";
}

#GuuruMain {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  z-index: 2147483640;
  text-align: initial;
}

.guuru-launcher-icon-close {
  position: absolute;
  inset: 0;
  display: none;
  width: 100%;
  text-align: center;
  color: white;
}

.guuru-launcher-icon-close > svg {
  width: calc(100% - 33px);
  height: calc(100% - 35px);
  margin: 17px;
  left: 0;
  position: absolute;
  top: 0;
}

.guuru-launcher-icon {
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
}

.guuru-launcher-icon > svg {
  left: 0;
  position: absolute;
  top: 0;
  width: 62%;
  height: auto;
  margin: 18%;
}

.guuru-launcher-icon.custom {
  height: 100%;
}

.mod--size-small .guuru-launcher-icon > svg {
  width: 28px;
  height: 25px;
  margin: 11px;
}

.guuru-chat {
  height: calc(100% - 120px);
  max-height: var(--max-chat-height);
  min-height: var(--min-chat-height);
  display: none;
  position: fixed;
  overflow: hidden;
  width: 370px;
  border-radius: 10px;
  box-shadow: 0 1px 6px rgba(0 0 0 / 6%), 0 2px 32px rgba(0 0 0 / 16%) !important;
  z-index: 1002;
  animation: open 0.25s ease-in-out;
}

.guuru-chat.mod--type-squared {
  border-radius: 0;
}

.guuru-launcher-button.mod--transparent:hover {
  box-shadow: none;
}

.guuru-chat-visible #GuuruMain .guuru-chat {
  display: block;
}

.guuru-chat-visible #GuuruMain .guuru-launcher-icon {
  display: none;
}

.guuru-chat-visible #GuuruMain .guuru-launcher-icon-close {
  display: block;
}

.guuru-launcher.mod--type-circle.mod--size-small
.guuru-launcher-active-badge {
  bottom: -35px;
  left: 1px;
}

.guuru-launcher.mod--type-circle.mod--size-medium
.guuru-launcher-active-badge {
  bottom: -45px;
  left: -1px;
}

.guuru-launcher.mod--type-circle.mod--size-large
.guuru-launcher-active-badge {
  bottom: -59px;
  left: -4px;
}

.guuru-launcher.mod--type-round.mod--size-small
.guuru-launcher-active-badge {
  bottom: -43px;
  left: 6px;
}

.guuru-launcher.mod--type-round.mod--size-medium
.guuru-launcher-active-badge {
  bottom: -53px;
  left: 6px;
}

.guuru-launcher.mod--type-round.mod--size-large
.guuru-launcher-active-badge {
  bottom: -73px;
  left: 6px;
}

.guuru-launcher.mod--type-squared.mod--size-small
.guuru-launcher-active-badge {
  bottom: -43px;
  left: 6px;
}

.guuru-launcher.mod--type-squared.mod--size-medium
.guuru-launcher-active-badge {
  bottom: -53px;
  left: 6px;
}

.guuru-launcher.mod--type-squared.mod--size-large
.guuru-launcher-active-badge {
  bottom: -73px;
  left: 6px;
}

.guuru-chat-visible #GuuruMain .guuru-launcher-active-badge {
  display: none;
}

.guuru-chat-visible #GuuruMain .guuru-launcher-button.mod--transparent {
  box-shadow: 0 1px 6px rgba(0 0 0 / 6%), 0 2px 32px rgba(0 0 0 / 16%);
}

.guuru-chat-visible #GuuruMain .guuru-launcher-button.mod--transparent:hover {
  box-shadow: 0 1px 6px rgba(0 0 0 / 40%), 0 2px 32px rgba(0 0 0 / 20%);
}

.guuru-chat-initialized .guuru-launcher {
  display: block;
}

.guuru-chat-nonWorkingHours .guuru-launcher {
  display: none;
}

.guuru-chat-disabled .guuru-launcher {
  display: none;
}

@media (min-width: 768px) {
  .guuru-chat-visible-auto .guuru-launcher {
    display: inherit !important;
  }
}

@media only screen and (max-width: 768px) {
  .guuru-chat-visible.mod--chatOpen-m {
    display: block;
  }

  .guuru-chat-initialized.guuru-chat-hideUntilScroll .guuru-launcher.mod--hus-m {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .guuru-chat-visible.mod--chatOpen-m {
    display: block;
  }

  .guuru-chat-initialized.guuru-chat-hideUntilScroll .guuru-launcher.mod--hus-t {
    display: none;
  }
}

@media only screen and (min-width: 1025px) {
  .guuru-chat-visible.mod--chatOpen-m {
    display: block;
  }

  .guuru-chat-initialized.guuru-chat-hideUntilScroll .guuru-launcher.mod--hus-d {
    display: none;
  }
}

.guuru-launcher-button:hover {
  box-shadow: 0 1px 6px rgba(0 0 0 / 40%), 0 2px 32px rgba(0 0 0 / 20%);
}

.guuru-launcher.mod--position-centerRight {
  top: 50%;
  right: 0;
}

.guuru-launcher.mod--position-centerLeft {
  top: 50%;
  left: 0;
}

.guuru-launcher.mod--position-bottomRight {
  bottom: 20px;
  right: 20px;
}

.guuru-launcher.mod--position-bottomLeft {
  bottom: 20px;
  left: 20px;
}

.guuru-launcher.mod--type-circle.mod--size-small {
  width: var(--height-launcher-size-small);
  height: var(--height-launcher-size-small);
}

.guuru-launcher.mod--type-circle.mod--size-medium {
  width: var(--height-launcher-size-medium);
  height: var(--height-launcher-size-medium);
}

.guuru-launcher.mod--type-circle.mod--size-large {
  width: var(--height-launcher-size-large);
  height: var(--height-launcher-size-large);
}

.guuru-launcher.mod--type-round.mod--size-small {
  width: var(--height-launcher-size-small);
  height: var(--height-launcher-size-small);
}

.guuru-launcher.mod--type-round.mod--size-medium {
  width: var(--height-launcher-size-medium);
  height: var(--height-launcher-size-medium);
}

.guuru-launcher.mod--type-round.mod--size-large {
  width: var(--height-launcher-size-large);
  height: var(--height-launcher-size-large);
}

.guuru-launcher.mod--type-squared.mod--size-small {
  width: var(--height-launcher-size-small);
  height: var(--height-launcher-size-small);
}

.guuru-launcher.mod--type-squared.mod--size-medium {
  width: var(--height-launcher-size-medium);
  height: var(--height-launcher-size-medium);
}

.guuru-launcher.mod--type-squared.mod--size-large {
  width: var(--height-launcher-size-large);
  height: var(--height-launcher-size-large);
}

.guuru-iframe {
  height: 100%;
  display: block;
  border: 0;
}

.guuru-chat.mod--position-bottomRight.mod--type-circle, .guuru-chat.mod--position-bottomRight.mod--type-round, .guuru-chat.mod--position-bottomRight.mod--type-squared {
  right: 20px;
}

.guuru-chat.mod--position-bottomLeft.mod--type-circle, .guuru-chat.mod--position-bottomLeft.mod--type-round, .guuru-chat.mod--position-bottomLeft.mod--type-squared {
  left: 20px;
}

.guuru-chat.launcher-large {
  bottom: 115px;
}

.guuru-chat.launcher-medium {
  bottom: 95px;
}

.guuru-chat.launcher-small {
  bottom: 85px;
}

@media (max-width: 600px) {
  body.guuru-chat-visible {
    position: static !important;
    min-height: 100%;
    height: 100%;
  }

  .guuru-chat {
    max-height: 100%;
  }

  .guuru-chat-visible > *:not(#GuuruMain) {
    display: none;
  }

  .guuru-chat-visible #GuuruMain {
    display: block;
  }

  .guuru-chat-visible #GuuruMain, .guuru-chat-visible .guuru-chat {
    width: 100%;

    /**
     * the window resize listener may run on mobile and set incorrect
     * height.
     */
    height: 100% !important;
  }

  .guuru-chat-visible .guuru-chat {
    position: static;
    border-radius: 0;
  }

  .guuru-chat-visible #GuuruMain .guuru-launcher {
    display: none;
  }
}

.guuru-conversion-iframe, .guuru-storage-iframe {
  display: none;
}

.guuru-smart-form-expand-iframe-wrapper {
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2147483640;
  width: 100%;
  height: 100% !important;
  max-height: 100% !important;
  overflow: hidden;
}

.guuru-smart-form-expand-iframe {
  border-radius: 0 !important;
  height: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
  .guuru-smart-form.guuru-smart-form-expanded {
    z-index: 2147483639 !important;
  }

  .guuru-contact {
    width: 100% !important;
    overflow: hidden;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
