// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-community-embed-j0Cm4 {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: 10px 0;
}

.index-text-M03mc {
  display: flex;
  cursor: pointer;
  text-decoration: underline !important;
  padding: 0 !important;
  margin-left: 7px;
;
}

.index-text-M03mc:hover {
  font-weight: bold !important;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"community-embed": `index-community-embed-j0Cm4`,
	"communityEmbed": `index-community-embed-j0Cm4`,
	"text": `index-text-M03mc`
};
export default ___CSS_LOADER_EXPORT___;
