import { gql } from '@urql/core';

export default gql`
  query GetPersistedMentions(
    $id: ID!,
    $urls: [String],
    $locale: String,
    $first: Int,
    $after: String,
    $isFirstPage: Boolean!,
  ) {
    community (id: $id, locale: $locale){
      id
      mentions(urls: $urls, first: $first, after: $after) {
        edges {
          node {
            id
            createdAt
            summary
            headline
            author {
              externalId
              bio
              name
              photoUrl
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        count @include(if: $isFirstPage)
      }
    }
  }
`;
