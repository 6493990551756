import Launcher from './modules/launcher';
import Conversion from './modules/conversion';
import CONSTANTS from './helpers/constants';
import Settings from './helpers/settings';
import ChatWidget from './modules/chatWidget';
import Loader from './modules/loader';
import SmartForm from './modules/smartForm';
import Vanilla from './helpers/vanilla';

const PUBLIC_API = {
  // Sets options that come from the PP, this is called in configJsonp
  configure: (options) => {
    Settings.setConfigOption(options || {});
  },

  isInitialized: () => (!!document.querySelector('#GuuruMain')),

  init: (initOptions) => {
    if (PUBLIC_API.isInitialized()) {
      Loader.destroy();
    }
    const options = initOptions || {};
    if (!options.appId) {
      throw new Error(CONSTANTS.VALIDATION.APP_ID);
    }

    Loader.init(options);
  },

  destroy: () => {
    Loader.destroy();
  },

  openChat: () => {
    ChatWidget.showChat();
  },

  closeChat: () => {
    ChatWidget.hideChat();
  },

  toggleChat: () => {
    ChatWidget.toggleChat();
  },

  showChatButton: () => {
    Launcher.showChatLoader();
  },

  showChatLoader: () => {
    Vanilla.deprecation('"showChatLoader" is deprecated. Please use "showChatButton" instead.');
    Launcher.showChatLoader();
  },

  hideChatButton: () => {
    Launcher.hideChatLoader();
  },

  hideChatLoader: () => {
    Vanilla.deprecation('"hideChatLoader" size is deprecated. Please use "hideChatButton" instead.');
    Launcher.hideChatLoader();
  },

  isChatOpened: () => (
    ChatWidget.isVisible()
  ),

  isChatClosed: () => (
    !ChatWidget.isVisible()
  ),

  changeLocale: (locale) => {
    if (!PUBLIC_API.isInitialized()) {
      return;
    }
    const wasChatOpened = ChatWidget.isVisible();
    Loader.destroy();
    const settings = Settings.getAll();
    Loader.load({ ...settings, locale });
    if (wasChatOpened) {
      ChatWidget.showChat();
    }
  },

  changeCategory: (category) => {
    if (!PUBLIC_API.isInitialized()) {
      return;
    }
    const wasChatOpened = ChatWidget.isChatOpened();
    Loader.destroy();
    const settings = Settings.getAll();
    Loader.load({ ...settings, category });
    if (wasChatOpened) {
      ChatWidget.showChat();
    }
  },

  getVersion: () => (
    process.env.CI_COMMIT_SHORT_SHA
  ),

  getSettings: () => (
    Settings.getAll()
  ),

  trackPurchase: (args) => {
    Conversion.trackPurchase(args);
  },

  attachForm: () => {
    SmartForm.attachForm();
  },

  isChatEnabled: () => {
    const settings = Settings.getAll();
    return Loader.isChatButtonEnabled(settings);
  },
};

export default PUBLIC_API;
