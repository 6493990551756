import Settings from '../helpers/settings';
import Vanilla from '../helpers/vanilla';

const defaultSettings = {
  spaces: undefined,
};

const HELPER = {
  defaultSettings,
  initSpaces() {
    const settings = Settings.getAll();
    const { spaces } = settings;
    const spacesParam = Vanilla.getParameterByName('spaces');
    const spacesArray = spacesParam ? spacesParam.split(',') : undefined;
    settings.spaces = (spacesArray || spaces);
  },
  defaultSpacePath(spaces) {
    const spacesPaths = new Map([['community', 'guurus']]);
    const [defaultSpace] = spaces || [];

    return spacesPaths.has(defaultSpace) ? spacesPaths.get(defaultSpace) : '';
  },
};
export default HELPER;
