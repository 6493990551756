let settings = {};
let configOptions = {};

const HELPER = {
  add(setting, value) {
    settings[setting] = value;
  },
  set(newSettings) {
    settings = newSettings;
  },
  getAll() {
    return settings;
  },
  get(setting) {
    return settings[setting];
  },
  delete(setting) {
    delete settings[setting];
  },
  setConfigOption(options) {
    configOptions = options;
  },
  getConfigOptions() {
    return configOptions;
  },
};

export default HELPER;
