import CONSTANTS from '../helpers/constants';
import Grabber from './grabber';
import Vanilla from '../helpers/vanilla';
import Conversion from './conversion';
import Settings from '../helpers/settings';
import CommunityLauncher from './communityLauncher';

let launcher = null;
let grabber = null;
let chat = null;
let initialLauncherBottom = null;
let initialGrabberBottom = null;
let initialChatBottom = null;

const defaultSettings = {
  launcher: {
    enabled: true,
    hideUntilScroll: false,
    hideUntilScrollTargetDevices: [],
    hideActiveBadge: false,
    launcherColor: 'rgba(51, 165, 219, 1)',
    transparent: false,
    textColor: 'white',
    position: 'bottomRight',
    marginRight: undefined,
    borderRadius: '50%',
    customIcon: false,
    icon: 'comments',
    size: 'medium',
    format: 'default',
  },
  launcherOpenColor: 'rgba(51, 165, 219, 1)',
  launcherType: 'round',
  openTriggers: [],
  stickyFooterSelector: undefined,
};

const getLauncherType = function (borderRadius) {
  switch (borderRadius) {
    case '0%':
      return 'squared';
    case '10%':
      return 'round';
    default:
      return 'circle';
  }
};

const getDefaultLauncherSettings = (settings) => ({
  launcherFormat: settings.launcher.format,
  launcherBorderRadius: settings.launcher.borderRadius,
});

const getLauncherFormat = function (settings) {
  const communityLoader = settings.autoMessages.find(({
    url,
    action,
    content,
    category,
    targetDevices,
  }) => Vanilla.isForCurrentDeviceTarget(targetDevices)
      && Vanilla.hasPageTargeting(settings, url, category)
      && action === 'communityLoader'
      && content[settings?.locale]);
  return (communityLoader || settings.launcher.format === 'community')
    ? CommunityLauncher.getLauncherSettings(settings, communityLoader)
    : getDefaultLauncherSettings(settings);
};

const prepareLauncher = function () {
  const settings = Settings.getAll();
  const {
    launcher: {
      backgroundColor,
      launcherColor,
      transparent,
      textColor,
      position,
      marginRight,
      borderRadius,
      customIcon,
      icon,
      size,
    } = {},
    openTriggers,
  } = settings;

  const launcherFormat = getLauncherFormat(settings);

  Object.assign(settings, {
    launcherColor: backgroundColor || launcherColor,
    launcherOpenColor: backgroundColor || launcherColor,
    launcherTransparent: transparent,
    launcherTextColor: textColor,
    launcherPosition: position,
    launcherType: getLauncherType(launcherFormat.launcherBorderRadius),
    ...launcherFormat,
  });

  if (!Vanilla.contains(
    CONSTANTS.SUPPORTED_LAUNCHER_POSITIONS,
    settings.launcherPosition,
  )) {
    throw new Error(CONSTANTS.VALIDATION.LAUNCHER_POSITIONS);
  }

  Object.assign(settings, {
    launcherMarginRight: marginRight,
    launcherBorderRadius: borderRadius,
    launcherCustomIcon: customIcon,
    launcherIcon: icon,
  });

  if (!settings.launcherCustomIcon && !Vanilla.contains(
    CONSTANTS.SUPPORTED_LAUNCHER_ICONS,
    settings.launcherIcon,
  )) {
    Object.assign(settings, {
      launcherIcon: CONSTANTS.DEFAULT_LAUNCHER_ICON,
    });
  }

  if (size === 'default') {
    Vanilla.deprecation('"default" launcher size is deprecated. Please use "medium" instead.');
  } else {
    Object.assign(settings, {
      launcherSize: size || defaultSettings.launcher.size,
    });
  }

  if (!Vanilla.contains(
    CONSTANTS.SUPPORTED_LAUNCHER_SIZES,
    settings.launcher.size,
  )) {
    throw new Error(CONSTANTS.VALIDATION.LAUNCHER_SIZES);
  }

  if (!Array.isArray(openTriggers)) {
    throw new Error(CONSTANTS.VALIDATION.OPEN_TRIGGERS);
  }
};

const getMods = function ({
  launcherPosition,
  launcherType,
  launcherSize,
  launcherFormat,
  launcher: {
    hideUntilScrollTargetDevices,
  } = {},
}) {
  const position = `mod--position-${launcherPosition}`;
  const type = `mod--type-${launcherType}`;
  const format = `mod--format-${launcherFormat}`;

  const size = `mod--size-${launcherSize}`;
  let mods = `${position} ${type} ${size} ${format}`;

  const hideUntilScrollDevices = hideUntilScrollTargetDevices;

  if (hideUntilScrollDevices && hideUntilScrollDevices.length > 0) {
    if (Vanilla.contains(hideUntilScrollDevices, 'mobile')) {
      mods += ' mod--hus-m';
    }

    if (Vanilla.contains(hideUntilScrollDevices, 'tablet')) {
      mods += ' mod--hus-t';
    }

    if (Vanilla.contains(hideUntilScrollDevices, 'desktop')) {
      mods += ' mod--hus-d';
    }
  }

  return mods;
};

const getChatIframe = function () {
  const classes = 'js-guuru-iframe guuru-iframe';

  const iframe = ` \
    <iframe class='${classes}' \
            src='' \
            width='100%' height='100%'> \
    </iframe>`;

  return iframe;
};

const getButtonMods = function ({
  launcherTransparent,
  launcherFormat,
  launcherCustomIcon,
}) {
  if (launcherFormat !== 'custom') {
    return '';
  }
  return launcherCustomIcon && launcherTransparent
    ? 'mod--transparent'
    : '';
};

const getLauncherStyle = function ({ launcherMarginRight }) {
  if (launcherMarginRight) {
    return `right: ${launcherMarginRight}`;
  }
  return '';
};

const getLauncherButtonStyle = function ({
  launcherColor,
  launcherBorderRadius,
  launcherFormat,
  launcherCustomIcon,
  launcherTransparent,
  launcherTextColor,
}) {
  if (launcherFormat === 'custom') {
    if (launcherCustomIcon && launcherTransparent) {
      return `border-radius: ${launcherBorderRadius}`;
    }
  }

  if (launcherFormat !== 'default') {
    return `background-color: ${launcherColor}; color: ${launcherTextColor}`;
  }

  return `background-color: ${launcherColor}; border-radius: ${launcherBorderRadius}`;
};

const getCustomLauncherIconStyle = function ({
  launcherIcon,
  launcherBorderRadius,
}) {
  return `
      background-image: url(${launcherIcon});
      background-size: contain;
      border-radius: ${launcherBorderRadius};
    `;
};

const getLauncherIconStyle = function ({ launcherTextColor }) {
  return `color: ${launcherTextColor};`;
};

const getFontAwesomeLauncherButton = function ({
  launcherTextColor,
  launcherIcon,
}) {
  const classes = 'js-guuru-launcher-icon guuru-launcher-icon';
  const launcherIconStyle = getLauncherIconStyle({ launcherTextColor });

  const icon = ` \
    <i class='${classes}' aria-hidden='true' style='${launcherIconStyle}'> \
      ${CONSTANTS.ICONS[launcherIcon]} \
    </i>`;

  return icon;
};

const getCustomLauncherButton = function (settings) {
  const classes = 'js-guuru-launcher-icon guuru-launcher-icon custom';
  const launcherIconStyle = getCustomLauncherIconStyle(settings);

  const icon = ` \
    <i class='${classes}' aria-hidden='true' style='${launcherIconStyle}'> \
    </i>`;

  return icon;
};

const getLauncherButtonActiveBadge = function (settings) {
  const {
    launcher: { hideActiveBadge } = {},
    launcherFormat,
  } = settings;
  if (hideActiveBadge || launcherFormat !== 'default') return '';

  const classes = 'guuru-launcher-active-badge';

  const activeBadge = ` \
    <span class='${classes}' aria-hidden='true'> \
    </span>`;

  return activeBadge;
};

const getLauncherButton = function (settings) {
  if (settings.launcherFormat === 'community') {
    return CommunityLauncher.getLauncherButton(settings);
  }
  if (settings.launcherCustomIcon === true) {
    return getCustomLauncherButton(settings);
  }
  return getFontAwesomeLauncherButton(settings);
};

const getLauncherCloseButton = function ({ launcherTextColor }) {
  const classes = 'guuru-launcher-icon-close';
  const style = `color: ${launcherTextColor};`;

  return ` \
    <i class='${classes}' aria-hidden='true' style='${style}'> \
      ${CONSTANTS.ICONS.close} \
    </i>`;
};

const getLauncherHtml = function () {
  const settings = Settings.getAll();
  const {
    launcherColor,
    launcherSize,
  } = settings;
  const iframe = getChatIframe();
  const conversionGoalsIframes = Conversion.getIframes();
  const mods = getMods(settings);
  const buttonMods = getButtonMods(settings);
  const launcherStyle = getLauncherStyle(settings);
  const launcherButtonStyle = getLauncherButtonStyle(settings);
  const launcherButton = getLauncherButton(settings);
  const launcherCloseButton = getLauncherCloseButton(settings);
  const launcherButtonActiveBadge = getLauncherButtonActiveBadge(settings);
  const grabberHtml = Grabber.getGrabberHtml(settings, mods);
  const chatClasses = `js-guuru-chat guuru-chat ${mods} launcher-${launcherSize}`;
  const chatStyle = `background-color: ${launcherColor};`;
  const launcherClasses = `js-guuru-launcher guuru-launcher ${mods}`;
  const lbClass = 'guuru-launcher-button';
  const launcherButtonClasses = `js-${lbClass} ${lbClass} ${buttonMods}`;

  const template = ` \
      <div class='${chatClasses}' style="${chatStyle};"> \
        ${iframe} \
      </div> \

      <div class='js-guuru-conversion'> \
        ${conversionGoalsIframes} \
      </div> \

      <div class='${launcherClasses}' style='${launcherStyle}'> \
        ${grabberHtml} \

        <div class='${launcherButtonClasses}' style='${launcherButtonStyle}'> \
          ${launcherButton} \
          ${launcherCloseButton} \
          ${launcherButtonActiveBadge} \
        </div> \
      </div>`;

  return template;
};

const attachChat = function () {
  const { launcher: { zIndex } } = Settings.getAll();

  const oldDiv = document.querySelector('#GuuruMain');
  if (oldDiv) {
    oldDiv.remove();
  }

  const template = getLauncherHtml();
  const element = document.createElement('div');
  element.innerHTML = template;
  element.id = 'GuuruMain';
  if (zIndex) {
    element.style.zIndex = zIndex;
  }
  Vanilla.appendChild(document.body, element);
};

const HELPER = {
  defaultSettings,
  prepareLauncher,
  attachChat,

  showLoaderInIncludedPageUrl(enabled) {
    const { launcher: { displayRules: { includeUrls } } } = Settings.getAll();
    let isEnabled = enabled;
    const currentUrl = window.location.href;
    const currentUrlDecoded = decodeURIComponent(window.location.href);

    if (Array.isArray(includeUrls) && includeUrls.length > 0) {
      // If there are display rules urls then there must be at least one
      // matching pattern
      isEnabled = false;

      // Check if current locale belongs to rules list
      const rulesUrls = includeUrls;
      for (let i = 0; i < rulesUrls.length; i += 1) {
        const urlPattern = rulesUrls[i];
        const rePattern = new RegExp(urlPattern, 'g');
        // If at least one url matches then it should be enabled
        if (currentUrl.match(rePattern) || currentUrlDecoded.match(rePattern)) {
          isEnabled = true;
          break;
        }
      }
      if (!isEnabled) {
        Vanilla.debugMessage('Chat is not enabled (reason: settings.displayRulesIncludeUrls).');
      }
    }
    return isEnabled;
  },

  showLoaderInExcludedPageUrl(enabled) {
    const {
      launcher: { displayRules: { excludeUrls } },
    } = Settings.getAll();
    let showLoader = enabled;
    const currentUrl = window.location.href;
    const currentUrlDecoded = decodeURIComponent(window.location.href);
    if (Array.isArray(excludeUrls) && excludeUrls.length > 0) {
      // Check if current locale belongs to rules list
      const rulesUrls = excludeUrls;
      for (let i = 0; i < rulesUrls.length; i += 1) {
        const urlPattern = rulesUrls[i];
        const rePattern = new RegExp(urlPattern, 'g');

        // If at least one url matches then it should be disabled
        if (currentUrl.match(rePattern) || currentUrlDecoded.match(rePattern)) {
          showLoader = false;
          const debugMsg = 'Chat is not enabled'
              + ' (reason: settings.launcher.displayRules.excludeUrls). Details: '
              + `pattern=${urlPattern}`;
          Vanilla.debugMessage(debugMsg);
          break;
        }
      }
    }

    return showLoader;
  },

  isEnabledByUrl() {
    // By default it is enabled
    let enabled = true;
    enabled = this.showLoaderInIncludedPageUrl(enabled);
    enabled = this.showLoaderInExcludedPageUrl(enabled);
    return enabled;
  },

  showChatLoader() {
    Vanilla.removeClass(document.body, 'guuru-chat-disabled');
    HELPER.recalculateChatElementPositions();
  },

  hideChatLoader() {
    Vanilla.addClass(document.body, 'guuru-chat-disabled');
    Vanilla.removeClass(document.body, 'guuru-chat-visible-auto');
    HELPER.recalculateChatElementPositions();
  },

  isChatLoaderVisible() {
    return !Vanilla.hasClass(document.body, 'guuru-chat-disabled');
  },

  initStickyElements() {
    [launcher] = document.getElementsByClassName('guuru-launcher');
    [grabber] = (
      document.getElementsByClassName('js-guuru-launcher-grabber')
    );
    [chat] = document.getElementsByClassName('guuru-chat');
    initialLauncherBottom = parseFloat(getComputedStyle(launcher).bottom);
    initialGrabberBottom = parseFloat(getComputedStyle(grabber).bottom);
    initialChatBottom = parseFloat(getComputedStyle(chat).bottom);
    HELPER.recalculateChatElementPositions();
  },

  recalculateChatElementPositions() {
    const { stickyFooterSelector } = Settings.getAll();
    const stickySelectors = stickyFooterSelector;
    if (!stickySelectors || !launcher) {
      return;
    }
    const heights = stickySelectors.map((stickySelector) => {
      const [
        selector,
        shadowRootSelector,
      ] = stickySelector.split('.shadowRoot.');
      const stickyElement = shadowRootSelector
        ? document.querySelector(selector)
          ?.shadowRoot?.querySelector(shadowRootSelector)
        : document.querySelector(selector);
      const launcherElement = document.querySelector('.guuru-launcher');
      if (stickyElement
        && launcherElement
        && Vanilla.isInViewport(stickyElement)
        && Vanilla.shouldMoveLauncher(stickyElement, launcherElement)
      ) {
        let elementHeight = parseFloat(stickyElement.clientHeight);
        const clientHeight = (
          window.innerHeight || document.documentElement.clientHeight
        );
        const rect = stickyElement.getBoundingClientRect();
        elementHeight += Math.max(clientHeight - rect.bottom, 0);
        return elementHeight;
      }
      return 0;
    });
    const stickyHeight = Math.max(...heights, 0);
    launcher.style.setProperty('bottom', `${stickyHeight + initialLauncherBottom}px`, 'important');
    grabber.style.setProperty('bottom', `${stickyHeight + initialGrabberBottom}px`, 'important');
    if (Vanilla.hasClass(document.body, 'guuru-chat-disabled')
    || Vanilla.hasClass(document.body, 'guuru-chat-hideUntilScroll')) {
      grabber.style.setProperty('margin-bottom', `-${initialGrabberBottom - initialLauncherBottom}px`, 'important');
    } else {
      grabber.style.setProperty('margin-bottom', '0', 'important');
    }

    if (Vanilla.currentDeviceTarget() !== 'mobile') {
      requestAnimationFrame(() => {
        chat.style.setProperty('bottom', `${stickyHeight + initialChatBottom}px`, 'important');
        chat.style.setProperty('height', `calc(100% - ${120 + stickyHeight}px)`, 'important');
      });
    }
  },
};

export default HELPER;
