import Vanilla from '../helpers/vanilla';
import DynamicSettings from '../helpers/dynamicSettings';
import Settings from '../helpers/settings';

const defaultSettings = {
  trackLeads: false,
  loggedInCssClass: '',
};

const getPrepared = function () {
  const settings = Settings.getAll();
  const { trackLeads, loadFeatures = {} } = settings;
  settings.trackLeads = trackLeads && loadFeatures.leads;
  if (!settings.trackLeads) {
    Vanilla.debugMessage(`Leads track is not enabled (reason: settings.trackLeads=${trackLeads} && settings.loadFeatures.leads=${loadFeatures.leads} )`);
    return false;
  }
  return true;
};

const HELPER = {
  defaultSettings,
  initTrackLeads() {
    const isEnabled = getPrepared();
    if (!isEnabled) return;

    const settings = Settings.getAll();
    if (settings.loggedInCssClass) {
      const isLoggedIn = document.querySelector(
        `.${settings.loggedInCssClass}`,
      );
      if (isLoggedIn) {
        DynamicSettings.add('isUser', true);
      } else {
        DynamicSettings.add('isLead', true);
      }
    }
  },

};
export default HELPER;
