import styles from './index.css';
import CONSTANTS from '../../../helpers/constants';
import Settings from '../../../helpers/settings';
import { button, heroes } from '../../../components';
import STATS from '../../../helpers/stats';
import Vanilla from '../../../helpers/vanilla';

export default async function (embed) {
  const {
    avatars,
    contentText: buttonTitle,
    buttonDescriptionText,
    embedButtonColor,
  } = embed;
  const {
    appId,
    locale,
    embed: { avgRatingEmbedLabel, embedExpertsLabel } = {},
  } = Settings.getAll();

  const stats = await STATS.getCommunityStats(appId);
  if (!stats) {
    Vanilla.debugMessage('Community stats not loaded');
    return '';
  }
  const { expertsCount, avgRating } = stats;

  const talkToExpertsButton = button({
    selector: embed.selector || CONSTANTS.SELECTOR.communityEmbedExtended,
    title: buttonTitle,
    description: buttonDescriptionText,
  });

  const element = document.createElement('div');
  element.style.setProperty('--guuru-embed-color', embedButtonColor);
  element.style.setProperty('text-align', 'center');
  element.innerHTML = `
    <div class="${styles.embedExtendedInfo}">
      ${heroes({ avatars, width: 48 })}
      <div class="${styles.embedExtendedStats}">
        <p class="${styles.expertCount}">${expertsCount} ${embedExpertsLabel[locale || 'en']}</p>
        <p class="${styles.averageRating}">⭐ ${avgRating} ${avgRatingEmbedLabel[locale || 'en']}</p>
      </div>
    </div>
    ${talkToExpertsButton}`;
  return element.outerHTML;
}
