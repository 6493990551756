// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-guuru-community-hero-bpEA3 {
  margin-bottom: 5px;
  background-size: cover;
  background-position: center;
  width: 100%;
  border: 1px solid #dbdbdb;
}

.index-guuru-community-block-wAUN0 {
  color: black;
  padding: 5px 0;
  text-align: center;
}

.index-guuru-community-block-wAUN0 > p:nth-child(1) {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}

.index-guuru-community-block-wAUN0 > p:nth-child(2) {
  line-height: 1.5;
  font-size: 12px;
  padding: 0;
  margin: 0 0 10px;
}

.index-guuru-community-block-wAUN0 > div {
  text-align: left;
}

@media only screen and (max-width: 1024px) {
  .index-guuru-community-block-wAUN0 {
    padding-top: 5px;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .index-guuru-expert-content-wrapper-OAsOs .index-guuru-community-block-wAUN0 > div {
    width: 280px;
  }

  .index-guuru-community-block-wAUN0 > p:nth-child(2) {
    margin-bottom: 10px;
  }

  .index-guuru-community-hero-bpEA3 {
    height: 200px;
    width: 100%;
  }
}

`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"guuru-community-hero": `index-guuru-community-hero-bpEA3`,
	"guuruCommunityHero": `index-guuru-community-hero-bpEA3`,
	"guuru-community-block": `index-guuru-community-block-wAUN0`,
	"guuruCommunityBlock": `index-guuru-community-block-wAUN0`,
	"guuru-expert-content-wrapper": `index-guuru-expert-content-wrapper-OAsOs`,
	"guuruExpertContentWrapper": `index-guuru-expert-content-wrapper-OAsOs`
};
export default ___CSS_LOADER_EXPORT___;
