import Settings from '../../helpers/settings';
import Vanilla from '../../helpers/vanilla';
import Analytics from '../analytics';
import CONSTANTS from '../../helpers/constants';
import styles from './invitation.css';

const showIfLoaderHidden = () => {
  if (Vanilla.hasClass(document.body, 'guuru-chat-disabled')
  || Vanilla.hasClass(document.body, 'guuru-chat-nonWorkingHours')
  || Vanilla.hasClass(document.body, 'guuru-chat-hideUntilScroll')) {
    const grabber = document.querySelector('.js-guuru-launcher-grabber');
    const main = document.querySelector('#GuuruMain');
    main.insertAdjacentElement('beforeend', grabber);
  }
};

const handleCtaClick = () => {
  Analytics.trackEvent('invitation_grabber_click');
};

const attachEventListeners = () => {
  const invitationGrabberContentLink = document.querySelector(`${CONSTANTS.SELECTOR.invitationGrabberContent} a`);

  if (invitationGrabberContentLink) {
    invitationGrabberContentLink.addEventListener('click', handleCtaClick);
  }
};

const onLoad = ({ id, title }) => {
  showIfLoaderHidden();
  attachEventListeners({ id, title });
  Analytics.trackEvent('invitation_grabber_shown');
};

const getStyles = function () {
  const {
    launcherColor,
  } = Settings.getAll();

  return `.js-guuru-launcher-invitationGrabber-message a { color: white !important; background-color: ${launcherColor};}`;
};

const getHtml = function (content) {
  return (`<div class='js-guuru-launcher-invitationGrabber-content ${styles.invitationGrabberContent}'>
      <span class='js-guuru-launcher-invitationGrabber-message ${styles.invitationGrabberMessage}'>
      ${content}
      </span>
    </div>`);
};

const isVisible = function () {
  return document.querySelector(CONSTANTS.SELECTOR.invitationGrabberContent);
};

export default {
  getStyles,
  getHtml,
  onLoad,
  isVisible,
};
