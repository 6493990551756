import CONSTANTS from '../../../helpers/constants';
import styles from './index.css';
import { heroes } from '../../../components';

export default function (embed) {
  const { avatars, embedLinkColor, contentText } = embed;

  return `
    <div class='${styles.communityEmbed}'> \
      ${heroes({ avatars: avatars.slice(1), width: 38 })} \
      <a class="${styles.text} ${CONSTANTS.SELECTOR.communityEmbed}" style="color: ${embedLinkColor};"> \
        ${contentText} \
      </a>
    </div>`;
}
