import Vanilla from '../helpers/vanilla';
import SmartForm from './smartForm';
import DynamicSettings from '../helpers/dynamicSettings';
import Settings from '../helpers/settings';

const defaultSettings = {
  conversionGoals: undefined,
};

const ENDPOINTS = [{
  product: 'chat',
  url: `${process.env.CHAT_ENDPOINT}/_conversion/index.html`,
  selector: 'js-guuru-chat-conversion-iframe',
}, {
  product: 'form',
  url: `${process.env.FORM_ENDPOINT}/_conversion/index.html`,
  selector: 'js-guuru-form-conversion-iframe',
}];

const getEnabledEndpoints = function () {
  const isSmartFormEnabled = SmartForm.isEnabled();
  return ENDPOINTS.filter(({ product }) => (
    product !== 'form' || (product === 'form' && isSmartFormEnabled)
  ));
};

const getIframe = function (customClass) {
  const classes = `${customClass} guuru-conversion-iframe`;
  return `<iframe class='${classes}' width=0 height=0></iframe>`;
};

const HELPER = {
  defaultSettings,
  getIframes() {
    const { loadFeatures: { conversions } = {} } = Settings.getAll();
    if (!conversions) {
      Vanilla.debugMessage(`Conversion track is not enabled (reason: settings.loadFeatures.conversions=${conversions})`);
      return undefined;
    }
    return getEnabledEndpoints()
      .map(({ selector }) => getIframe(selector)).join('');
  },

  trackConversion(params) {
    const settingsParameters = Vanilla.generateParamsFromSettings(
      Settings.getAll(),
    );
    const dynamicParameters = Vanilla.generateParamsFromDynamicSettings(
      DynamicSettings.getAll(),
    );
    const trackParams = Vanilla.generateParams(params, Object.keys(params));
    const iframeParams = [settingsParameters, dynamicParameters, trackParams]
      .join('&');
    getEnabledEndpoints().forEach(({ url, selector }) => {
      const guuruConversionIframe = document.querySelector(`.${selector}`);
      if (guuruConversionIframe) {
        guuruConversionIframe.src = `${url}?${iframeParams}`;
      }
    });
  },

  trackPurchase(args) {
    if (!window.guuru.isInitialized()) {
      Vanilla.warnMessage('Chat button must be initialized to track conversions');
      return;
    }
    const { value, currency, goalId } = args || {};
    const settings = Settings.getAll();
    const valueProcessed = value
      || (settings && settings.purchase && String(settings.purchase.value));
    const currencyProcessed = currency
      || (settings && settings.purchase && settings.purchase.currency);
    const params = {
      pathName: window.location.pathname,
      partner: settings.appId,
      currency: currencyProcessed,
      value: valueProcessed,
      goalId,
    };
    HELPER.trackConversion(params);
  },

  runConversionGoals() {
    const { conversionGoals, purchase, appId } = Settings.getAll();
    if (conversionGoals && Array.isArray(conversionGoals)) {
      conversionGoals.forEach(({ url, id }) => {
        const pathName = window.location.pathname;
        const currentUrl = window.location.href;
        const rePattern = new RegExp(url, 'g');
        if (currentUrl.match(rePattern)) {
          const params = {
            goalId: id,
            pathName,
            partner: appId,
            currency: purchase && purchase.currency,
            value: purchase && purchase.value && String(purchase.value),
          };
          HELPER.trackConversion(params);
        }
      });
    }
  },
};

export default HELPER;
