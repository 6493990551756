import CONSTANTS from '../helpers/constants';
import Settings from '../helpers/settings';

const defaultSettings = {
  loadFeatures: {
    analytics: false,
    conversions: true,
    leads: true,
    proactive: true,
  },
};

const HELPER = {
  defaultSettings,
  prepareLoadFeatures() {
    const loadFeatures = Settings.get('loadFeatures');
    const featuresArray = Object.entries(loadFeatures);
    featuresArray.forEach(([featureName, featureValue]) => {
      if (typeof featureValue !== 'boolean') {
        throw new Error(CONSTANTS.VALIDATION.LOAD_FEATURES(`loadFeatures.${featureName} = '${featureValue}'`));
      }
    });
  },
};
export default HELPER;
