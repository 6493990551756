// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-content-embed-paginated-button-DuL5j {
  background-color: var(--guuru-embed-color);
  font-size: 16px;
  border: none;
  color: white;
  font-weight: 500;
  padding: 16px 24px;
  user-select: none;
  border-radius: var(--guuru-button-border-radius);

  &:hover {
    filter: contrast(85%);
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding: 15px 0;
    margin-top: 12px;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-embed-paginated-button": `index-content-embed-paginated-button-DuL5j`,
	"contentEmbedPaginatedButton": `index-content-embed-paginated-button-DuL5j`
};
export default ___CSS_LOADER_EXPORT___;
