import style from './index.css';
import Settings from '../../../../helpers/settings';
import actionButton from '../list/actionButton';
import { poweredBy } from '../../../../components';
import headline from '../list/embedHeadline';

const emptyList = (embed) => {
  const {
    locale,
    contentEmbed: {
      contentEmbedEmptyText,
    } = {},
  } = Settings.getAll();

  const { contentEmbedShowHeadline } = embed;

  return `
    <div>
    ${contentEmbedShowHeadline ? headline() : ''}
      <div class="${style.contentEmbedEmptyTextWrapper}">
        ${contentEmbedEmptyText?.[locale ?? 'en']}
        <div>
        ${actionButton(true)}
        </div>
        ${poweredBy('guuru-content-embed-powered-by')}
      </div>
    </div>
`;
};

export default emptyList;
