// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable selector-id-pattern, selector-class-pattern */

@keyframes index-open-Y9GOE {
  from {
    transform: translateY(10%);
    opacity: 0;
  }
}

.index-grabber-close-Em86k {
  display: none;
  box-sizing: border-box !important;
  position: absolute;
  width: 32px;
  height: 32px;
  padding: 8px;
  background-color: #5d6c80;
  opacity: 1;
  border-radius: 16px;
  cursor: pointer;
  line-height: 1;
  color: #fff;
}

@media only screen and (width <= 769px) {
  .index-grabber-close-Em86k {
    width: 26px;
    height: 26px;
    padding: 6px;
  }
}

.mod--format-community .index-grabber-close-Em86k {
  background-color: #67788f;
  box-shadow: 0 0 8px rgba(0 0 0 / 25%);
  right: auto;
}

@media only screen and (max-width: 769px) {
  .mod--format-community .index-grabber-close-Em86k {
    display: block;
    background-color: white;
    color: #000;
    line-height: 0;
    width: 20px;
    height: 20px;
    padding: 5px;
  }

  .mod--format-community .index-grabber-close-Em86k i {
    color: #000;
  }

  .mod--format-community .index-grabber-close-Em86k svg {
    width: 10px;
    height: 10px;
  }
}

@media only screen and (max-width: 769px) {
  .index-grabber-close-Em86k {
    display: block;
  }
}

.index-grabber-x6Xrx {
  opacity: 0;
  position: fixed;
  bottom: 20px;
  color: #2f2f2f;
  transition: opacity 0.5s ease-in-out;
  cursor: none;
  pointer-events: none;
  background-color: transparent !important;
  visibility: hidden;
}

.index-grabber-x6Xrx.index-fade-in-C9fRx {
  opacity: 1;
  cursor: pointer;
  pointer-events: all;
  visibility: visible;
}

.index-grabber-x6Xrx:hover .index-grabber-close-Em86k {
  display: block;
  animation: index-open-Y9GOE 0.25s ease-in-out;
}

.index-grabber-x6Xrx.mod--position-bottomRight {
  right: 20px;
}

.index-grabber-x6Xrx.mod--position-bottomLeft {
  left: 20px;
}

.index-grabber-x6Xrx.mod--size-small {
  bottom: calc(var(--height-launcher-size-small) + 30px);
}

.index-grabber-x6Xrx.mod--size-medium {
  bottom: calc(var(--height-launcher-size-medium) + 30px);
}

.index-grabber-x6Xrx.mod--size-large {
  bottom: calc(var(--height-launcher-size-large) + 30px);
}

.index-grabber-x6Xrx.mod--format-community {
  bottom: calc(var(--height-launcher-rounded-size-community) + 30px);
}

@media only screen and (max-width: 768px) {
  .index-grabber-x6Xrx.mod--format-community {
    bottom: calc(var(--height-grabber-size-mobile) + 20px);
  }
}

.index-grabber-options-JRXGl {
  height: 40px;
  cursor: default;
}

.index-positionLeft-z3M02 {
  left: 20px !important;
  padding-bottom: 70px;
  width: 300px;
}

@media only screen and (max-width: 769px) {
  .index-grabber-options-JRXGl {
    height: 30px;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grabber-close": `index-grabber-close-Em86k`,
	"grabberClose": `index-grabber-close-Em86k`,
	"grabber": `index-grabber-x6Xrx`,
	"fade-in": `index-fade-in-C9fRx`,
	"fadeIn": `index-fade-in-C9fRx`,
	"open": `index-open-Y9GOE`,
	"grabber-options": `index-grabber-options-JRXGl`,
	"grabberOptions": `index-grabber-options-JRXGl`,
	"positionLeft": `index-positionLeft-z3M02`
};
export default ___CSS_LOADER_EXPORT___;
