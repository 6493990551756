import CONSTANTS from '../../helpers/constants';
import Vanilla from '../../helpers/vanilla';
import Analytics from '../analytics';
import Events from '../events';

const {
  SELECTOR,
  GUURU_EVENT_NAMESPACE,
} = CONSTANTS;

const embedsSelectors = [
  SELECTOR.communityEmbed,
  SELECTOR.communityEmbedExtended,
  SELECTOR.contentEmbed,
  'guuru-content-embed-powered-by',
];

let watchedCounter = 0;

const getEventType = (nodeClassList = []) => {
  if (!nodeClassList.length) return null;
  const selector = embedsSelectors.find((sel) => (
    Array.from(nodeClassList).includes(sel)
  ));
  return selector.replace('guuru-', '').replace(/-/g, '_');
};

const getEmbedType = (eventName) => ((eventName.includes('content')) ? 'content' : 'community');

const handleIntersection = function (entries, observer) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const eventType = getEventType(entry.target?.classList);
      const eventName = eventType.concat('_shown');
      if (eventName) {
        Vanilla.debugMessage(`Sending ${eventName} analytics event`);
        Analytics.trackEvent(eventName);
        Events.processEvent({
          id: `${GUURU_EVENT_NAMESPACE}embed:isInViewPort`,
          data: { type: getEmbedType(eventType) },
        });
      }
      observer.unobserve(entry.target);
      watchedCounter -= 1;
      if (!watchedCounter) {
        Vanilla.debugMessage('All embeds were observed, disconnecting observer');
        observer.disconnect();
      }
    }
  });
};

const observer = new IntersectionObserver(handleIntersection);

const trackEmbedsShown = () => {
  embedsSelectors.forEach((embedSelector) => {
    const embed = document.querySelector(`.${embedSelector}`);
    if (embed) {
      observer.observe(embed);
      watchedCounter += 1;
    }
  });
};

export default trackEmbedsShown;
