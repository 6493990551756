import Settings from '../../../../../helpers/settings';
import styles from './index.css';

const headline = (count) => {
  const {
    locale = 'en',
    contentEmbed: {
      contentEmbedTitle,
      contentEmbedDescription,
    } = {},
  } = Settings.getAll();

  return `
    <div class="${styles.embedHeadline}" id="content-embed-headline">
      <h3>${contentEmbedTitle?.[locale]}${count ? ` (${count})` : ''}</h3>
      <p>
        <img alt="verified-icon" src="https://cdn.guuru.com/assets/icons/verified-icon.png">
        ${contentEmbedDescription?.[locale]}
      </p>
    </div>
  `;
};

export default headline;
