import Vanilla from '../helpers/vanilla';
import Settings from '../helpers/settings';

const defaultSettings = {
  category: '',
  breadcrumbMapper: {
    labels: {},
    itemSelector: '',
  },
  urlMapper: {},
};

const prepareCategoryBreadcrumbMapper = function () {
  const settings = Settings.getAll();
  const categoryBreadcrumbMapper = settings.breadcrumbMapper.labels;

  if (!Array.isArray(categoryBreadcrumbMapper) && typeof (categoryBreadcrumbMapper) === 'object') {
    // Validate all inputs
    const categories = Object.keys(categoryBreadcrumbMapper);
    for (let i = 0; i < categories.length; i += 1) {
      const category = categories[i];
      const categoryLabels = categoryBreadcrumbMapper[category];

      if (!Array.isArray(categoryLabels)) {
        const debugMsg = `Cannot prepare category breadcrumb labels (reson: breadcrumbMapper.labels["${category}"] is not of type "array")`;
        Vanilla.debugMessage(debugMsg);
        return;
      }

      // Check if all values are of type string
      for (let j = 0; j < categoryLabels.length; j += 1) {
        const label = categoryLabels[j];
        if (typeof (label) !== 'string') {
          const debugMsg = `Cannot prepare category breadcrumb labels (reson: breadcrumbMapper.labels["${category}"] has a value that is not of type "string")`;
          Vanilla.debugMessage(debugMsg);
          return;
        }
      }
    }
    settings.categoryBreadcrumbMapper = categoryBreadcrumbMapper;
  } else {
    const debugMsg = 'Cannot prepare category breadcrumb labels (reson: breadcrumbMapper.labels is not of type "object")';
    Vanilla.debugMessage(debugMsg);
  }
};

const prepareCategoryBreadcrumbSelector = function () {
  const settings = Settings.getAll();
  const categoryBreadcrumbSelector = settings.breadcrumbMapper.itemSelector;
  if (typeof (categoryBreadcrumbSelector) === 'string') {
    settings.categoryBreadcrumbSelector = categoryBreadcrumbSelector;
  } else {
    const debugMsg = 'Cannot prepare category breadcrumb selector (reson: breadcrumbMapper.itemSelector is not of type "string")';
    Vanilla.debugMessage(debugMsg);
  }
};

const prepareBreadcrumbMapperHash = function () {
  const { categoryBreadcrumbMapper } = Settings.getAll();
  // Convert breadcrumb mapper for a quick hash finder
  const categoryBreadcrumbMapperHash = {};
  const mapper = categoryBreadcrumbMapper;
  if (mapper) {
    const categories = Object.keys(mapper);
    for (let i = 0; i < categories.length; i += 1) {
      const category = categories[i];
      const labels = mapper[category];

      for (let j = 0; j < labels.length; j += 1) {
        const label = labels[j].toLowerCase();
        categoryBreadcrumbMapperHash[label] = category;
      }
    }
  }

  return categoryBreadcrumbMapperHash;
};

const initializeBreadcrumbMapper = function () {
  const settings = Settings.getAll();
  const selector = settings.categoryBreadcrumbSelector;
  const mapper = settings.categoryBreadcrumbMapper;

  if (selector && mapper) {
    // Extract list of breadcrumb items
    const breadcrumElements = document.querySelectorAll(selector);
    if (breadcrumElements.length > 0) {
      // Convert breadcrumb mapper for a quick hash finder
      const categoryBreadcrumbMapperHash = prepareBreadcrumbMapperHash();

      for (let i = 0; i < breadcrumElements.length; i += 1) {
        const entry = breadcrumElements[i];
        const label = entry.textContent.trim().toLowerCase();

        const match = categoryBreadcrumbMapperHash[label];
        if (match) {
          let debugMsg = `Category found via breadcrumb mapper (category="${match}")`;
          if (settings.category) {
            debugMsg += ` (overriding previous category "${settings.category}")`;
          }
          Vanilla.debugMessage(debugMsg);
          settings.category = match;
          return;
        }
      }

      Vanilla.debugMessage('Category not found via breadcrumb mapper');
    } else {
      const debugMsg = `Cannot retrieve breadcrumb elements (reason: no elements found for selector "${selector}")`;
      Vanilla.debugMessage(debugMsg);
    }
  }
};

const initializeUrlMapper = function () {
  const settings = Settings.getAll();
  const mapper = settings.urlMapper;
  if (mapper) {
    // for each breadcrum element, try to find a match
    const { patterns } = mapper;
    if (patterns) {
      const categories = Object.keys(patterns);
      for (let i = 0; i < categories.length; i += 1) {
        const category = categories[i];
        const pattern = patterns[category];

        // Verify if there is a match
        const pageUrl = window.location.href;

        if (pageUrl.match(pattern)) {
          // Category found, return
          settings.category = category;
          const debugMsg = `Category found via URL mapper (category="${category}")`;
          Vanilla.debugMessage(debugMsg);
          return;
        }
      }
      Vanilla.debugMessage('Category not found via URL mapper');
    }
  }
};

const initializeCategoryMapper = function () {
  initializeBreadcrumbMapper();
  initializeUrlMapper();
};

const HELPER = {
  defaultSettings,
  initCategoryMapper() {
    prepareCategoryBreadcrumbMapper();
    prepareCategoryBreadcrumbSelector();
    initializeCategoryMapper();
  },
};

export default HELPER;
