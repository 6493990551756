/* eslint-env browser */
import './src/styles/index.css';
import PublicApi from './src/public';
import './src/helpers/domPolyfills';

(function (window) {
  // Guuru Main Wrapper Function
  const Guuru = () => PublicApi;

  // We need that our library is globally accessible, then we save in the window
  if (typeof (window.Guuru) === 'undefined') {
    // eslint-disable-next-line no-param-reassign
    window.Guuru = Guuru;
    const evt = document.createEvent('Event');
    evt.initEvent('guuru:loaded', true, true);
    document.dispatchEvent(evt);
  }
}(window)); // We send the window variable withing our function
