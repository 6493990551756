import { cacheExchange, Client, fetchExchange } from '@urql/core';
import { persistedExchange } from '@urql/exchange-persisted';

const client = new Client({
  url: `${process.env.CORE_ENDPOINT}`,
  exchanges: [
    cacheExchange,
    persistedExchange({ preferGetForPersistedQueries: true }),
    fetchExchange,
  ],
  fetchOptions: {
    headers: {
      'Content-Type': 'application/json',
    },
  },
});

export default client;
