// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-content-feedback-V10rg {
  display: flex;
  align-items: center;

  p:first-child {
    margin: 0 12px;
  }

  .index-content-feedback-buttons-ihnnt {
    font-size: 14px;
    display: flex;
    padding-bottom: 2px;

    input {
      width: 18px;
      height: auto;
      opacity: 0.3;
    }

    input:disabled {
      opacity: 1;
    }

    input:first-child {
      margin: 0 14px 0 16px;
    }
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-feedback": `index-content-feedback-V10rg`,
	"contentFeedback": `index-content-feedback-V10rg`,
	"content-feedback-buttons": `index-content-feedback-buttons-ihnnt`,
	"contentFeedbackButtons": `index-content-feedback-buttons-ihnnt`
};
export default ___CSS_LOADER_EXPORT___;
